import { BehaviorSubject, Observable } from 'rxjs';
import { SortBy } from './sort.util';
import { LoadBoardService } from '../services/load-board/load-board.service'
import { map, tap } from 'rxjs/operators';


export class AdminLoadboardStore {
    loadboards: BehaviorSubject<Array<any>>;
    paginator: Promise<{}>;
    stats: {};
    constructor(private _init_loadboards: Observable<any>, private loadboardService: LoadBoardService) {
        this.loadboards = this.convertIntoBehavior(_init_loadboards);

    }

    private convertIntoBehavior(data: Observable<any>): BehaviorSubject<any> {
        let bs = new BehaviorSubject([]);

        let sub = data.pipe(tap(value => {

            this.paginator = new Promise((d) => d(value.data));
        }), map(value => this.getActivesWebsites(value.data))).subscribe((value) => bs.next(value));

        return bs;
    }

    getActivesWebsites(websites) {
        return websites.map(web => {
            return { ...web, isActiveMyUser: web.brokers && web.brokers?.length > 0 ? web.brokers[0].active : false }
        })
    }

    reset(): void {
        this.loadboards = this.convertIntoBehavior(this._init_loadboards)
    }

    update(url: string, data) {
        const actionEdit = url.split(/[\/=]/gm);

        if (actionEdit.includes('disable-posting-sa') && actionEdit.includes('sa')) {
            const loadboards: Array<any> = this.loadboards.value;

            const index = loadboards.findIndex((loadboard) => loadboard._id === data.data._id);

            loadboards[index] = {
                ...data.data,
                isActiveMyUser: false
            }

            this.stats = this.getStats([...loadboards]);

            this.loadboards.next([
                ...loadboards
            ])
        }
        if (actionEdit.includes('enable-loadboard-by-company') ||
            actionEdit.includes('disable-loadboard-by-company') ||
            actionEdit.includes('stop-a-loadboard-to-users-by-company') ||
            actionEdit.includes('active-a-loadboard-to-users-by-company')) {
            const loadboards: Array<any> = this.loadboards.value;

            const index = loadboards.findIndex((loadboard) => loadboard._id === data._id);

            loadboards[index] = {
                ...data
            }

            this.stats = this.getStats([...loadboards]);

            this.loadboards.next([
                ...loadboards
            ])
        }
        if (actionEdit.includes('active-posting')) {
            const loadboards: Array<any> = this.loadboards.value;

            const index = loadboards.findIndex((loadboard) => loadboard._id === data.data._id);

            loadboards[index] = {
                ...data.data,
                isActiveMyUser: true
            }

            this.stats = this.getStats([...loadboards]);

            this.loadboards.next([
                ...loadboards
            ])
        }
        if (actionEdit.includes('create-account')) {
            let loadboards: Array<any> = this.loadboards.value;

            let index = loadboards.findIndex((loadboard) => loadboard._id === data.data._id);

            loadboards[index] = {
                ...data.data,
                isActiveMyUser: true
            }

            this.loadboards.next([
                ...loadboards
            ])
        }
        if (actionEdit.includes('active-all-posting') || actionEdit.includes('disable-all-posting')) {
            let finalData = this.getActivesWebsites(data);
            this.stats = this.getStats(finalData);
            this.loadboards.next([...finalData]);
        }

    }

    getStats(webs) {
        let activeStats = 0;
        let inactiveStats = 0;
        let unavailableStats = 0;

        webs.map(web => {
            if (!web.active) {
                unavailableStats++
            } else {
                if (web.brokers && web.brokers?.length > 0) {
                    web.brokers[0].active ? activeStats++ : inactiveStats++
                } else {
                    inactiveStats++
                }
            }
            return web
        });

        return {
            activeStats,
            inactiveStats,
            unavailableStats
        };
    }
}