import { BehaviorSubject, Observable } from 'rxjs';
import { SortBy } from './sort.util';
import { LoadBoardService } from '../services/load-board/load-board.service'
import { map, tap } from 'rxjs/operators';

export class WebsiteStore {
    websites: BehaviorSubject<Array<any>>;
    stats: {};
    typeSort: string;
    private sort: SortBy = new SortBy();
    private itsSortBy: Set<string> = new Set();

    constructor(private _init_shipments: Observable<any>, private loadboardService: LoadBoardService) {
        this.websites = this.converIntoBehaviorWebsite(_init_shipments);
        // this._init_shipments = this.converIntoBehaviorWebsite(_init_shipments);
    }

    converIntoBehaviorWebsite(data: Observable<any>) {
        let bs = new BehaviorSubject([]);

        let sub = data.pipe(
            tap(value => {
                this.stats = this.getStats(value.data);
                value.data.sort((a, b) => a.websiteName.toLowerCase().localeCompare(b.websiteName.toLowerCase()))
            }),
            map(value => this.getActivesWebsites(value.data))
        ).subscribe((value) => bs.next(value));

        // data.pipe(map(value => {
        //     this.stats = this.getStats(value.data);
        // })).toPromise();
        return bs;
    }

    getStats(webs) {
        let activeStats = 0;
        let inactiveStats = 0;
        let unavailableStats = 0;

        webs.map(web => {
            if (!web.active) {
                unavailableStats++
            } else {
                if (web.brokers && web.brokers?.length > 0) {
                    web.brokers[0].active ? activeStats++ : inactiveStats++
                } else {
                    inactiveStats++
                }
            }
            return web
        });

        return {
            activeStats,
            inactiveStats,
            unavailableStats
        };
    }

    getActivesWebsites(websites) {
        websites.map(web => {
            return web.isActiveMyUser = web.brokers && web.brokers?.length > 0 ? web.brokers[0].active : false;
        })

        return websites.sort((a, b) => a.websiteName?.toLowerCase().localeCompare(b.websiteName?.toLowerCase()))
    }

    sortBy(propertyPath: string, stay: boolean = false, shipment?: Array<any>) {
        const shipments = shipment && shipment.length > 0 ? [...shipment] : [...this.websites.value];

        const { sortArray, typeSort } = this.sort.sortByWebsites(propertyPath, shipments, stay)

        if (typeSort !== 'no-sort') {
            this.itsSortBy.add(propertyPath)
        } else {
            this.itsSortBy.delete(propertyPath);
            this.typeSort = typeSort;
            return this.websites.next([...this._init_shipments['value']])
        }

        this.typeSort = typeSort;

        this.websites.next([...sortArray]);
    }

    reset(): void {
        this.websites = this.converIntoBehaviorWebsite(this._init_shipments);
    }

    update(url: string, data) {
        const actionEdit = url.split(/[\/=]/gm);

        if (actionEdit.includes('disable-posting')) {
            const loadboards: Array<any> = this.websites.value;

            const index = loadboards.findIndex((loadboard) => loadboard._id === data.data._id);

            loadboards[index] = {
                ...data.data,
                isActiveMyUser: false
            }

            this.stats = this.getStats([...loadboards]);

            this.websites.next([
                ...loadboards
            ])
        }
        if (actionEdit.includes('active-posting')) {
            const loadboards: Array<any> = this.websites.value;

            const index = loadboards.findIndex((loadboard) => loadboard._id === data.data._id);

            loadboards[index] = {
                ...data.data,
                isActiveMyUser: true
            }

            this.stats = this.getStats([...loadboards]);

            this.websites.next([
                ...loadboards
            ])
        }
        if (actionEdit.includes('create-account')) {
            let loadboards: Array<any> = this.websites.value;

            let index = loadboards.findIndex((loadboard) => loadboard._id === data.data._id);

            loadboards[index] = {
                ...data.data,
                isActiveMyUser: true
            }

            this.websites.next([
                ...loadboards
            ])
        }
        if (actionEdit.includes('active-all-posting') || actionEdit.includes('disable-all-posting')) {
            let finalData = data.filter(w => w);
            this.stats = this.getStats(finalData);
            this.websites.next([...this.getActivesWebsites(finalData)]);
        }
        if (actionEdit.includes('websites')) {
            let finalData = data.filter(w => w);
            
            this.stats = this.getStats(finalData);

            this.websites.next([...this.getActivesWebsites(finalData)]);
        }
    }
}