import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ShipmentService {

  constructor(private http: HttpClient) { }

  getShipments(query: { limit?: number, page?: number, sortField?: string, sortValue?: number }): Observable<any> {
    let params = new HttpParams().set('limit', query.limit || 10).set('page', query.page || 1);

    if (query.sortField) {
      params = params.set('sortField', query.sortField);
      params = params.set('sortValue', query.sortValue);
    }

    return this.http.get(`${environment.url}/shipment`, {
      params
    });
  }

  newGetShipment(query: { limit?: number, page?: number, sortField?: string, sortValue?: string }, body = {}): Observable<any> {
    let params = new HttpParams().set('limit', query.limit || 10).set('page', query.page || 1);

    if (query.sortField) {
      params = params.set('sortField', query.sortField);
      params = params.set('sortValue', query.sortValue);
    }

    return this.http.post(`${environment.url}/shipment/filter`, body, {
      params
    });
  }

  getShipmentArchive(query: { limit?: number, page?: number, sortField?: string, sortValue?: string }) {
    let params = new HttpParams().set('limit', query.limit || 10).set('page', query.page || 1);

    if (query.sortField) {
      params = params.set('sortField', query.sortField);
      params = params.set('sortValue', query.sortValue);
    }
    return this.http.get(`${environment.url}/shipment/all/archived`, {
      params
    })
  }
  getShipmentFavorite(query: { limit?: number, page?: number, sortField?: string, sortValue?: string }) {
    let params = new HttpParams().set('limit', query.limit || 10).set('page', query.page || 1);

    if (query.sortField) {
      params = params.set('sortField', query.sortField);
      params = params.set('sortValue', query.sortValue);
    }

    return this.http.get(`${environment.url}/shipment/all/favorite`, {
      params
    })
  }

  getShipmentById(id): Observable<any> {
    return this.http.get(`${environment.url}/shipment/` + id);
  }

  getArchivedShipmentById(id): Observable<any> {
    return this.http.get(`${environment.url}/shipment/archive/` + id);
  }

  postLoad(load, post = false): Observable<any> {
    const params = new HttpParams().set('post', post);
    return this.http.post(`${environment.url}/shipment`, load, { params });
  }

  loadsStatusLoadboard(id: string) {
    return this.http.get(`${environment.url}/shipment/loadboard/posted?id=${id}`)
  }

  postLoads(loadIds: string[]): Observable<any> {
    return this.http.post(`${environment.url}/shipment/post-loads`, { loadIds });
  }

  unpostLoads(loadIds: string[]): Observable<any> {
    return this.http.post(`${environment.url}/shipment/unpost`, { shipments: loadIds });
  }

  setShipmentArchive(id: string, archived = true) {
    return this.http.put(`${environment.url}/shipment/archived/` + id, { archived });
  }
  setShipmentsArchive(id: string[], page: number, limit: number): Observable<any> {
    return this.http.post(`${environment.url}/shipment/archived-many/archived`, { shipments: id, page, limit });
  }
  setShipmentsActive(id: string[]): Observable<any> {
    return this.http.post(`${environment.url}/shipment/archived-many/active`, { shipments: id });
  }
  setShipmentFavorite(id: string) {
    return this.http.put(`${environment.url}/shipment/favorite/` + id, { favorite: true });
  }

  editShipmentAndPost(shipments: any, isPosting?: boolean): Observable<any> {
    let request = { shipments, isPosting }
    return this.http.post(`${environment.url}/shipment/edit-many`, request);
  }

  editShipment(shipments: Array<any>, isPosting?: boolean): Observable<any> {
    return this.http.post(`${environment.url}/shipment/edit-many`, { shipments, isPosting: isPosting });
  }

  rollDates(payload): Observable<any> {
    return this.http.post(`${environment.url}/shipment/update-pickup-shipment`, payload);
  }

  generalSearch(search: string, act: string) {
    return this.http.get(`${environment.url}/shipment/general/search`, {
      params: {
        search,
        act
      }
    })
  }

  exportLoadCSV(ids) {
    return this.http.post(`${environment.url}/shipment/export-loads-csv`, ids, {
      headers: {
        Accept: 'application/octet-stream'
      },
      responseType: 'blob'
    });
  }

  starShipment(id: string, star: boolean) {
    return this.http.put(`${environment.url}/shipment/star/` + id, { star });
  }


  importLoadCSV(file) {
    let exportLoads = new FormData();

    exportLoads.append('file', file);

    return this.http.post(`${environment.url}/shipment/import-load-csv`, exportLoads)
  }

  postShipmentOnLoadboard(shipmentId: string, loadBoardId: string): Observable<any> {
    return this.http.post(`${environment.url}/shipment/posting-loadboard`, { shipmentId, loadBoardId })
  }

  unpostShipmentOnLoadboard(shipmentId: string, loadBoardId: string): Observable<any> {
    return this.http.post(`${environment.url}/shipment/unpost-loadboard`, { shipmentId, loadBoardId })
  }

  getCompanyAccounts(companyId): Observable<any> {
    return this.http.get(`${environment.url}/shipment/company-account/${companyId}`)
  }

  filterActive(obj) {
    return this.http.post(`${environment.url}/shipment/filter`, obj)
  }

  editManyFavorite(shipments: { _id: string; archived: boolean }[]): Observable<any> {
    return this.http.post(`${environment.url}/shipment/edit-no-favorite`, shipments)
  }

  removeArchive(id, page: number, limit: number): Observable<any> {
    return this.http.post(`${environment.url}/shipment/delete-archive`, { shipments: id, page, limit })
  }

  duplicateLoadsAndPost(shipments: any, isPosting: boolean): Observable<any> {
    let payload = { shipments, isPosting }
    return this.http.post(`${environment.url}/shipment/duplicate/load`, payload)
  }

  favoriteMany(shipments: Array<string>) {
    return this.http.post(`${environment.url}/shipment/favorite-many`, {
      favorite: true,
      shipments
    })
  }

  createManyShipment(shipments, post: boolean): Observable<any> {
    return this.http.post(`${environment.url}/shipment/create-many`, shipments, {
      params: {
        post
      }
    })
  }

  getPersonalLoadboards(token: string): Observable<any> {
    return this.http.get(`${environment.url}/shipment/public/loads`, {
      params: {
        token
      }
    });
  }

  getTokenOLP(shipmentId: string, token: string) {
    return this.http.get(`${environment.url}/shipment/get/token/${shipmentId}/olp`, {
      params: {
        token
      }
    });
  }
}