import { BehaviorSubject, Observable } from 'rxjs';
import { SortBy } from './sort.util';
import { UserService } from '../services/user/user.service'
import { map, tap } from 'rxjs/operators';


export class UserStore {
    users: BehaviorSubject<Array<any>>;
    paginator: Promise<{}>;
    initialData;
    constructor(private _init_users: Observable<any>, private userService: UserService) {
        this.users = this.convertIntoBehavior(_init_users);

    }

    private convertIntoBehavior(data: Observable<any>): BehaviorSubject<any> {
        let bs = new BehaviorSubject([]);

        let sub = data.pipe(tap(value => {
            this.paginator = new Promise((d) => d(value.data));
            this.initialData = value.data.docs;
        }), map(value => value.data.docs)).subscribe((value) => bs.next(value));

        return bs;
    }

    reset(): void {
        this.users = this.convertIntoBehavior(this._init_users);
    }

    updatedPaginator({ pageIndex, pageSize, querySearch, companyId }) {
        this.userService.getUserList({ page: pageIndex + 1, limit: pageSize, dato: querySearch, companyId }).toPromise();
    }

    update(url: string, data) {
        const actionEdit = url.split(/[\/=]/gm);

        if (actionEdit.includes('sa?limit')) {
            this.users.next([...data.docs]);
            this.paginator = new Promise((d, r) => d(data));
        }

        if (actionEdit.includes('create-account-user')) {
            const newUser = { ...data };

            const users = [newUser, ...this.users.value];

            this.users.next([
                ...users,
            ]);
        }

        const updateUserInList = (_data) => {
            const users: Array<any> = this.users.value;

            const index = users.findIndex((usr) => usr._id == _data._id);

            users[index] = {
                ..._data
            }

            this.users.next([
                ...users
            ]);
        }

        if (actionEdit.includes('active-user')) {
            updateUserInList(data);
        }

        if (actionEdit.includes('user') && actionEdit.includes('sa')) {
            if (data.isDeleted) {
                const users: Array<any> = [...this.users.value];
                const index = users.findIndex((usr) => usr._id == data._id);

                if (index >= 0) {
                    users.splice(index, 1)
                    this.users.next([
                        ...users
                    ]);
                }


            } else {
                updateUserInList(data.data);
            }

        }
    }
}