import { BehaviorSubject, Observable } from 'rxjs';
import { SortBy } from './sort.util';
import { CompanyService } from '../services/company/company.service'
import { map, tap } from 'rxjs/operators';


export class CompanyStore {
    companies: BehaviorSubject<Array<any>>;
    paginator: Promise<{}>;
    initialData;
    private itsSortBy: Set<string> = new Set();
    constructor(private _init_companies: Observable<any>, private companyService: CompanyService) {
        this.companies = this.convertIntoBehavior(_init_companies);

    }

    private convertIntoBehavior(data: Observable<any>): BehaviorSubject<any> {
        let bs = new BehaviorSubject([]);

        let sub = data.pipe(tap(value => {
            this.paginator = new Promise((d) => d(value.data));
            this.initialData = value.data.docs;
        }), map(value => value.data.docs)).subscribe((value) => bs.next(value));

        return bs;
    }

    updatedPaginator({ pageIndex, pageSize, querySearch }) {
        this.companyService.getCompanies({ page: pageIndex + 1, limit: pageSize, dato: querySearch }).toPromise();
    }

    reset(): void {
        this.companies = this.convertIntoBehavior(this._init_companies);
    }

    update(url: string, data) {
        const actionEdit = url.split(/[\/=]/gm);
        if (actionEdit.includes('sa?limit')) {
            this.companies.next([...data.docs]);
            this.paginator = new Promise((d, r) => d(data));
        }

        if (actionEdit.includes('create-account-sa')) {
            const newCompany = { ...data.company };

            const companies = [newCompany, ...this.companies.value];

            this.companies.next([
                ...companies,
            ]);
        }

        if (actionEdit.includes('update-status') ||
            actionEdit.includes('update-enightly-purge') ||
            actionEdit.includes('company') ||
            actionEdit.includes('update-tms')) {
            const active: Array<any> = this.companies.value;

            const index = active.findIndex((comp) => comp._id == data._id);

            active[index] = {
                ...data
            }

            this.companies.next([
                ...active
            ]);
        }
    }
}