import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ShipmentDataService {
  private locationTypes = [
    'Military base',
    'Airport',
    'Port',
    'CFS',
    'Residential',
    'Commercial',
    'Yard'
  ];

  private accessorials = [
    'Appointment - Delivery',
    'Appointment - Pickup',
    'Blind Shipment',
    'CFS Pickup - Pickup',
    'Construction Site - Delivery',
    'Correction of Terms',
    'Cubic Capacity',
    'Detention',
    'Diversion',
    'Document Fee',
    'Dock Unload',
    'Dry Run',
    'Handling Fee',
    'Hand Unloading',
    'Hazardous Material - Pickup',
    'High Cost Area Fee',
    'Inside Delivery - Delivery',
    'Inside Pickup - Pickup',
    'Labeling',
    'Labor',
    'Layover',
    'Liftgate - Delivery',
    'Liftgate - Pickup',
    'Limited Access Delivery - Delivery',
    'Limited Access Pickup - Pickup',
    'Crating & Material',
    'Over Length - 12ft but less than 16ft - Delivery',
    'Over Length - 16ft but less than 20ft - Delivery',
    'Over Length - 8ft  but less than 12ft - Delivery',
    'Overheight',
    'Over Length Charge - Delivery',
    'Oversize Charge',
    'Overwidth',
    'Overweight Fee',
    'Re-Class',
    'Residential - Delivery',
    'Residential - Pickup',
    'Saturday Pickup',
    'Single Shipment Charge - Pickup',
    'Sort and Segregation',
    'Storage',
    'Stop Charge - Delivery',
    'Tarp Fee - Pickup',
    'Team Transit  - Pickup',
    'Uplift min max - Rating',
    'UPS Orders',
    'White Glove Service',
  ];

  private packageTypes = [
    'A-FRAME',
    'Bags',
    'Bales',
    'Barrel',
    'Basket',
    'Bins',
    'Box',
    'Break bulk',
    'Bundle',
    'Cans',
    'Cart',
    'Cartons',
    'Case',
    'Chest',
    'Container',
    'Crate',
    'Cylinder',
    'Drum',
    'Gallons',
    'Gaylords',
    'Halfpallets',
    'Hopper',
    'Jerricans',
    'Lift van',
    'Loose',
    'Package',
    'Pails',
    'Pallet',
    'Piece',
    'Racks',
    'Reel',
    'Roll',
    'Skid',
    'Stacks',
    'Totes',
    'Truckload',
    'Tube',
    'Tubs',
    'U-box',
    'Unit',
    'Vehicle',
    'Wheels'
  ];

  private classList = [
    '1.1 Mass Explosive Hazard',
    '1.2 Projection Hazard',
    '1.3 Fire and/or Minor Blast/Minor Projection Hazard',
    '1.4 Minor Explosion Hazard',
    '1.5 Very Insensitive with Mass Explosion Hazard',
    '1.6 Extremely Insensitive: No Mass Explosion Hazard',
    '2.1 Flammable Gases',
    '2.2 Nonflammable Gases',
    '2.3 Toxic Gases',
    '3 Flammable and Combustible Liquids',
    '4.1 Flammable Solids',
    '4.2 Spontaneously Combustible',
    '4.3 Dangerous When Wet',
    '5.1 Oxidizing Substances',
    '5.2 Organic Peroxides',
    '6.1 Toxic Substances',
    '6.2 Infectious Substances',
    '7 Radioactive Material',
    '8 Corrosives',
    '9 Miscellaneous'
  ];

  private packingsGroup = [
    'I: High danger',
    'II: Medium danger',
    'III: Low danger',
    'NA'
  ];

  getLocationTypes(): string[] {
    return this.locationTypes;
  }

  getAccessorials(): string[] {
    return this.accessorials;
  }

  getPackageTypes(): string[] {
    return this.packageTypes;
  }

  getClassList(): string[] {
    return this.classList;
  }

  getPackingsGroup(): string[] {
    return this.packingsGroup;
  }

}