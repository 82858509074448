import { Injectable } from '@angular/core'
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LoadBoardService {
    constructor(private http: HttpClient) { }

    getWebsites(): Observable<any> {
        return this.http.get(`${environment.url}/load-board/websites`);
    }

    createPosting(id: string): Observable<any> {
        return this.http.put(`${environment.url}/load-board/create-account/` + id, {});
    }

    activePosting(id: string, body): Observable<any> {
        return this.http.put(`${environment.url}/load-board/active-posting/` + id, body);
    }

    disablePosting(id: string): Observable<any> {
        return this.http.put(`${environment.url}/load-board/disable-posting/` + id, {});
    }

    updateAccount(id: string, body) {
        return this.http.put(`${environment.url}/load-board/update-account/` + id, body)
    }

    verifyAccount(id: string, body) {
        return this.http.put(`${environment.url}/load-board/verify-account/` + id, body)
    }

    activateAllLoadboard(loadboards: string[]) {
        return this.http.put(`${environment.url}/load-board/active-all-posting`, { ids: loadboards })
    }

    deactivateAllLoadboard(loadboards: string[]) {
        return this.http.put(`${environment.url}/load-board/disable-all-posting`, { ids: loadboards })
    }

    activateLoadboardToUser(user) {
        return this.http.put(`${environment.url}/load-board/active-user-posting`, user)
    }

    getAdminLoadBoards(userId): Observable<any> {
        return this.http.get(`${environment.url}/load-board/websites/${userId}/sa`);
    }

    activateLoadboardToUserFromSA(body) {
        return this.http.post(`${environment.url}/load-board/active-user-posting/sa`, body)
    }

    verifyAccountSA(loadboardId, userId, body) {
        return this.http.put(`${environment.url}/load-board/verify-account/${loadboardId}/${userId}/sa`, body)
    }

    activePostingSA(id: string, userId, body): Observable<any> {
        return this.http.put(`${environment.url}/load-board/active-posting/${id}/${userId}/sa`, body);
    }

    updateAccountSA(id: string, userId, body) {
        return this.http.put(`${environment.url}/load-board/update-account/${id}/${userId}/sa`, body)
    }

    createPostingAccountSA(id: string, userId: string): Observable<any> {
        return this.http.put(`${environment.url}/load-board/create-account/${id}/${userId}/sa`, {});
    }

    disablePostingSA(id: string, userId: string): Observable<any> {
        return this.http.put(`${environment.url}/load-board/disable-posting-sa/${id}/${userId}/sa`, {});
    }

    activateAllLoadboardSA(userId: string, loadboards: string[]) {
        return this.http.put(`${environment.url}/load-board/active-all-posting/${userId}/sa`, { ids: loadboards })
    }

    deactivateAllLoadboardSA(userId, loadboards: string[]) {
        return this.http.put(`${environment.url}/load-board/disable-all-posting/${userId}/sa`, { ids: loadboards })
    }

    getLoadboardsByCompany(companyId) {
        return this.http.get(`${environment.url}/load-board/websites/by-companyid/${companyId}/sa`)
    }

    activateAllLoadboardsByCompany(companyId) {
        return this.http.post(`${environment.url}/load-board/active-loadboards-to-users-by-company/sa`, {
            companyId
        })
    }

    activateAllLoadBoardsToUsers(userIds) {
        return this.http.post(`${environment.url}/load-board/active-all-loadboards-to-users/sa`, {
            users: userIds,
        })
    }

    enableLoadboard(companyId, id) {
        return this.http.post(`${environment.url}/load-board/enable-loadboard-by-company/sa`, {
            id,
            companyId
        })
    }

    disableLoadboard(companyId, id) {
        return this.http.post(`${environment.url}/load-board/disable-loadboard-by-company/sa`, {
            id,
            companyId
        })
    }

    startPostingSACompany(companyId, id) {
        return this.http.post(`${environment.url}/load-board/active-a-loadboard-to-users-by-company/sa`, {
            id,
            companyId
        })
    }

    stopPostingSACompany(companyId, id) {
        return this.http.post(`${environment.url}/load-board/stop-a-loadboard-to-users-by-company/sa`, {
            id,
            companyId
        })
    }
}