import { Injectable } from '@angular/core';
import { UtilsService } from '@services';
import { Subject } from 'rxjs';
declare let google;

@Injectable({
    providedIn: 'root'
})
export class GoogleServices {
    constructor(private utils: UtilsService) { }

    placesAutocomplete(inputId: string) {
        const INPUT = (document.getElementById(inputId) as HTMLInputElement);
        const OPTIONS = {
            types: ['(regions)'],
            componentRestrictions: { country: ['US', 'CA', 'MX'] },
        };
        const AUTOCOMPLETE = new google.maps.places.Autocomplete(INPUT, OPTIONS);
        const SUBJECT = new Subject<any>();

        google.maps.event.addListener(AUTOCOMPLETE, 'place_changed', () => {
            try {
                const PLACE = AUTOCOMPLETE.getPlace();
                const LOCATION = {
                    zipCode: PLACE.address_components?.find(element => element.types.includes('postal_code'))?.short_name,
                    city: PLACE.address_components?.find(element => element.types.includes('locality'))?.long_name || PLACE.address_components?.find(element => element.types.includes('sublocality'))?.long_name,
                    state: PLACE.address_components?.find(element => element.types.includes('administrative_area_level_1'))?.short_name,
                    stateLong: this.utils.removeAccent(PLACE.address_components?.find(element => element.types.includes('administrative_area_level_1'))?.long_name),
                    lat: PLACE.geometry.location.lat(),
                    lng: PLACE.geometry.location.lng(),
                    country: PLACE.address_components?.find(element => element.types.includes('country'))?.long_name
                }
                SUBJECT.next(LOCATION);
            } catch (error) {
                console.log(error);
            }
        });

        return SUBJECT.asObservable();
    }
}