import { Component, forwardRef, Input, NgModule, OnInit, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'switch',
  styleUrls: ['./switch.component.scss'],
  templateUrl: './switch.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true
    }
  ]
})
export class SwitchComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() id: string;

  valueSubs: Subscription;
  currentValue: FormControl;

  value: any;
  onChange: any = (_: any) => { }
  onTouch: any = (_: any) => { }

  constructor() {
    this.currentValue = new FormControl(false);
  }

  ngOnInit(): void {
    this.onChangeForm();
  }

  ngOnDestroy(): void {
    this.valueSubs?.unsubscribe();
  }

  onChangeForm() {
    this.valueSubs = this.currentValue.valueChanges
      .subscribe(value => {
        this.value = value;
        this.onTouch();
        this.onChange(this.value);
      });
  }

  writeValue(value: any): void {
    this.currentValue.setValue(Boolean(value));
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
}

@NgModule({
  declarations: [SwitchComponent],
  imports: [FormsModule, ReactiveFormsModule],
  exports: [SwitchComponent]
})

export class SwitchModule { }