import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GeneralLoadsComponent } from './general-shipments/general-shipments.component';

import { LoginComponent } from './login/login.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', loadChildren: () => import('./forgot-password/forgot-password.component').then(m => m.ForgotPasswordModule) },
  { path: 'verify-account', loadChildren: () => import('./verify-account/verify-account.component').then(m => m.VerifyAccountModule) },
  { path: 'register', loadChildren: () => import('./register/register.component').then(m => m.RegisterModule) },
  { path: 'app', loadChildren: () => import('./layout/layout.component').then(m => m.LayoutModule) },
  { path: 'personal-loads/:personalToken', component: GeneralLoadsComponent }
]

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', useHash: true })],
  exports: [RouterModule]
})
export class RoutingModule { }
