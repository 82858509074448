import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { result } from 'lodash';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class BookingService {
    _http: HttpClient = inject(HttpClient)
    constructor() { }

    getListBookings(query: { limit?: number, page?: number, sortField?: string, sortValue?: string }) {
        let params = new HttpParams().set('limit', query.limit || 10).set('page', query.page || 1);

        if (query.sortField && query.sortValue) {
            params.set('sortField', query.sortField);
            params.set('sortValue', query.sortValue);
        }

        return this._http.get(`${environment.url}/booking?${params.toString()}`);
    }

    getListBookingsByFilter(query: { limit?: number, page?: number, sortField?: string, sortValue?: string }, filters = {}) {
        let params = new HttpParams().set('limit', query.limit || 10).set('page', query.page || 1);

        if (query.sortField && query.sortValue) {
            params.set('sortField', query.sortField);
            params.set('sortValue', query.sortValue);
        }

        return this._http.post(`${environment.url}/booking/filter?${params.toString()}`, { ...filters });
    }

    getBookingByID(id: string) {
        return this._http.get(`${environment.url}/booking/${id}`);
    }

    removeBookingByID(id: string) {
        return this._http.delete(`${environment.url}/booking/${id}`)
    }

    removeBookingsByIDs(ids: string[]) {
        return this._http.post(`${environment.url}/booking/delete-many`, { ids })
    }

    getTokenOLP(shipmentId: string, token: string) {
        return this._http.get(`${environment.url}/booking/get/token/${shipmentId}/olp`, {
            params: {
                token
            }
        });
    }

    getUrlPayment() {
        return this._http.get(`${environment.url}/olp-payment/payment-url`).pipe(
            map(({ data }: any) => data)
        )
    }
    getUrlFundingSource() {
        return this._http.get(`${environment.url}/olp-payment/generate-registration-link`).pipe(
            map(({ data }: any) => data.registration_link)
        )
    }
}