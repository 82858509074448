import { inject, Injectable, Injector } from '@angular/core';
import { ActiveStore } from './active.store';
import { FavoriteStore } from './favorite.store';
import { ArchiveStore } from './archive.store';
import { WebsiteStore } from './loadboard.store';
import { AdminLoadboardStore } from './admin-loadboard.store';
import { CompanyStore } from './company.store';
import { UserStore } from './user.store';
import { BookingStore } from './booking.store';
import { ShipmentService, LoadBoardService, CompanyService, UserService, BookingService } from '@services';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../environments/environment';

@Injectable()
export class GlobalStore {
    private store: any = {
        booking: inject(BookingStore)
    }
    static injector: Injector;
    constructor(
        private injector: Injector,
        private shipmentService: ShipmentService,
        private loadboardService: LoadBoardService,
        private companyService: CompanyService,
        private userService: UserService) {
        GlobalStore.injector = this.injector;
    }

    main(): Promise<void> {
        return new Promise((d, r) => {
            this.store = {
                ...this.store,
                favorites: new FavoriteStore(this.shipmentService.getShipmentFavorite({ limit: 500, page: 1 }), this.shipmentService),
                archive: new ArchiveStore(this.shipmentService.getShipmentArchive({ limit: 500, page: 1 }), this.shipmentService),
                loadboards: new WebsiteStore(this.loadboardService.getWebsites(), this.loadboardService),
                active: new ActiveStore(this.shipmentService.newGetShipment({ limit: 25, page: 1 }), this.shipmentService),
                company: localStorage.role === 'superadmin' ? new CompanyStore(this.companyService.getCompanies({ limit: 25, page: 1 }), this.companyService) : null,
            }
            setTimeout(() => {
                d();
                if (environment.production) {
                    this.getEventSource("https://api.loadboardnetwork.com/api")
                    this.getEventSource("https://post.loadboardnetwork.com/api")
                } else {
                    this.getEventSource("https://api-dev.loadboardnetwork.com/api")
                }

            }, 1000)
        });
    }

    getEventSource(url: string) {
        let source = new EventSource(`${url}/shipment/see/event-update?token=${localStorage.getItem('token')}`)

        source.addEventListener('message', ({ data }) => {
            let action = JSON.parse(data).action;
            this.update(`/${action}`, JSON.parse(data))
        });

        source.addEventListener('error', (e) => {
            console.log(e)
        });
    }

    public get(name: TStores) {
        return this.store[name]
    }

    public getUserStore({ companyId, loadBoardId }, limit = 25, page = 1) {
        if (loadBoardId) {
            const userStore = new UserStore(this.userService.getUserListByLoadboardAndCompany(companyId, loadBoardId), this.userService)
            this.store.user = userStore;
            return userStore;
        }

        const userStore = new UserStore(this.userService.getUserList({ limit, page, companyId }), this.userService)
        this.store.user = userStore;
        return userStore;
    }

    public getAdminLoadBoardStore(userId: string, companyId: string) {
        if (userId) {
            const adminLBStore = new AdminLoadboardStore(this.loadboardService.getAdminLoadBoards(userId), this.loadboardService)
            this.store.adminLoadboard = adminLBStore;
            return adminLBStore;
        }

        const adminLBStore = new AdminLoadboardStore(this.loadboardService.getLoadboardsByCompany(companyId), this.loadboardService)
        this.store.adminLoadboard = adminLBStore;
        return adminLBStore;
    }

    convertIntoBehavior(data: Observable<any>): BehaviorSubject<any> {
        let bs = new BehaviorSubject([]);

        let sub = data.pipe(map(value => value.data.docs)).subscribe((value) => bs.next(value));

        return bs;
    }

    public update(url: string, { data }) {
        const actionEdit = url.split(/[\/?&=]/gm);

        const isSA = ['sa'].some(e => actionEdit.includes(e));
        const isActive = ['favorite', 'duplicate', 'add', 'favorite-many', 'page', 'post', 'edit-many', 'update-pickup-shipment', 'post-loads', 'archived-many', 'filter', 'import-load-csv', 'sortField', 'create-many', 'add-many', 'unpost', 'shipment'].some(e => actionEdit.includes(e));
        const isFavorite = ['favorite', 'page', 'favorite-many', 'edit-no-favorite', 'duplicate', 'edit-many', 'update-pickup-shipment', 'post-loads'].some(e => actionEdit.includes(e));
        const isArchive = ['delete-archive', 'page', 'archived-many', 'edit-many', 'update-pickup-shipment', 'post-loads', 'edit-no-favorite'].some(e => actionEdit.includes(e));
        const isWebsites = ['disable-posting', 'active-posting', 'create-account', 'active-all-posting', 'disable-all-posting', 'websites'].some(e => actionEdit.includes(e)) && !isSA;
        const isCompanies = ['update-enightly-purge', 'update-status', 'create-account-sa', 'company', 'update-tms'].some(e => actionEdit.includes(e));
        const isSAUser = ['active-user', 'create-account-user', 'user'].some(e => actionEdit.includes(e));
        const isSALoadboard = ['disable-posting-sa', 'disable-loadboard-by-company', 'stop-a-loadboard-to-users-by-company', 'active-a-loadboard-to-users-by-company', 'enable-loadboard-by-company', 'active-posting', 'create-account', 'active-all-posting', 'disable-all-posting'].some(e => actionEdit.includes(e)) && isSA;
        const isBooking = ['booking'].some(e => actionEdit.includes(e));

        if (isActive) {
            this.store.active.update(url, data);
        }
        if (isFavorite) {
            this.store.favorites.update(url, data)
        }

        if (isArchive) {
            this.store.archive.update(url, data)
        }

        if (isWebsites) {
            this.store.loadboards.update(url, data)
        }

        if (isCompanies) {
            this.store.company.update(url, data);
        }

        if (isSAUser) {
            this.store.user.update(url, data);
        }

        if (isSALoadboard) {
            this.store.adminLoadboard.update(url, data)
        }

        if(isBooking) {
            this.store.booking.update(url, data)
        }
    }
}

type TStores = 'active' | 'favorites' | 'archive' | 'loadboards' | 'company' | 'user' | 'adminLoadboard' | 'booking';