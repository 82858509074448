import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { ToastService } from '@services';
import { AuthService } from '@services';
import { Router } from '@angular/router';
import { asyncLoading } from '@decorators';
import Swal from 'sweetalert2';
import cryptoStorage from 'localstorage-slim';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  token: string;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private toastService: ToastService) {
    this.token = localStorage.getItem('token');
  }

  ngOnInit(): void {
    this.onInitForm();
    this.token ? this.router.navigate(['/app/loads/active']) : this.authService.logout();
  }

  onInitForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', Validators.required]
    });
  }

  goToForgotPassword() {
    this.router.navigate(['/forgot-password'])
  }

  @asyncLoading()
  login() {
    this.loginForm.markAllAsTouched();
    if (this.loginForm?.valid) {
      const credentials = { ...this.loginForm.value }
      this.authService.login(credentials).subscribe(response => {
        if (!response.status) {
          Swal.fire('User not verified', response.message, 'info')
        } else {
          this.saveData(response);
          this.toastService.showToast(`Welcome ${localStorage.getItem('name')}`, 'left');
          setTimeout(() => {
            response.data.user.role === 'superadmin' ? this.router.navigate(['/app/admin/companies']) : this.router.navigate(['/app/loads/active'])
          }, 500);
        }
      },
        error => {
          console.log(error);
          this.toastService.showToast(error?.error?.message || 'Error', 'left')
        }
      );
    } else {
      this.toastService.showToast('Invalid credential', 'left')
    }
  }

  saveData({ data }: { data: UserData }) {
    localStorage.setItem('token', data?.accessToken)
    localStorage.setItem('companyId', data?.user?.companyId);
    localStorage.setItem('role', data?.user?.role);
    localStorage.setItem('email', data?.user?.email);
    localStorage.setItem('name', `${data?.user?.firstName} ${data?.user?.lastName || ''}`);
    localStorage.setItem('phone', data?.user?.phone);
    localStorage.setItem('userName', data?.user?.userName);
    localStorage.setItem('profilePicture', data?.user?.picture);
    localStorage.setItem('isBookable', JSON.stringify(data?.user?.isBookable))
    localStorage.setItem('_id', data?.user?._id);
    cryptoStorage.set<Permissions>('permissions', {
      canSeeOtherShipment: data?.user?.canSeeOtherShipment
    }, { encrypt: true });
  }
}

interface UserData {
  accessToken: string;
  user: {
    companyId: string;
    createdAt: Date;
    customerKey: string;
    email: string;
    firstName: string;
    isActive: boolean;
    lastName: string;
    phone: string
    role: string
    serviceKey: string;
    updatedAt: Date;
    userName: string;
    picture: string;
    _id: string;
    otherShipmentReadOnly: boolean;
    canSeeOtherShipment: boolean;
    isBookable: boolean
  }
}

export interface Permissions {
  canSeeOtherShipment: boolean;
}
