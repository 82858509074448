import { Component, EventEmitter, Input, NgModule, OnInit, Output, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserAction } from '../../../constants';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-shipment-action-buttons',
  templateUrl: './shipment-action-buttons.component.html',
  styleUrls: ['./shipment-action-buttons.component.scss']
})
export class ShipmentActionButtonsComponent implements OnInit {
  @Input() action: UserAction;
  @Input() disabled = false;
  @Output() onCopyToActive = new EventEmitter<boolean>()
  @Output() onCopyToFavorite = new EventEmitter<boolean>()
  @Output() onSaveDuplicate = new EventEmitter<boolean>()
  @Output() onCancel = new EventEmitter<any>()
  @Output() onSave = new EventEmitter<any>()

  get userAction(): typeof UserAction {
    return UserAction;
  }
  
  constructor() { }

  @HostListener('document:keydown.Alt.≈')
  @HostListener('document:keydown.Alt.x')
  cancel() {
    this.onCancel.emit();
  }

  @HostListener('document:keydown.Alt.ƒ')
  @HostListener('document:keydown.Alt.f')
  copy() {
    console.log('hola')
    this.onCopyToFavorite.emit()
  }

  @HostListener('document:keydown.Alt.ß', ['$event'])
  @HostListener('document:keydown.Alt.s', ['$event'])
  @HostListener('document:keydown.Alt.π', ['$event'])
  @HostListener('document:keydown.Alt.p', ['$event'])
  save(event: KeyboardEvent) {
    if(event.code === 'KeyS') this.onSave.emit(false)
    if(event.code === 'KeyP') this.onSave.emit(true)
  }

  ngOnInit(): void { }
}

@NgModule({
  declarations: [ShipmentActionButtonsComponent],
  imports: [CommonModule, MatTooltipModule, MatIconModule],
  exports: [ShipmentActionButtonsComponent, MatTooltipModule]
})

export class ShipmentActionButtonsModule { }