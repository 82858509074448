import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class CreateService {
    private apiUrl = `${environment.url}/`;
    constructor(private http: HttpClient) { }

    postLoad() { 
        return 'Testing';
    }
}