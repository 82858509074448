import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { tap, catchError, finalize } from 'rxjs/operators';
import { MessagesLoading } from '../../pipes/loading-messages'

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('token');
    let headers;

    if (!token) return next.handle(req);

    if (req.url.includes('public/loads')) {
      headers = req.clone({
        headers: req.headers.delete('Authorization', `Bearer ${token}`)
      });
    } else if (req.url.includes('reports') && !req.url.includes('authentication')) {
      headers = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${localStorage.reportsToken}`)
      });
    } else {
      headers = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${token}`)
      });
    }

    let message = new MessagesLoading().transform(req.url);
    if (message) {
      window.dispatchEvent(new CustomEvent('showToast', { detail: message }));
    }

    let url = req.url.split('/');
    ['post-loads', 'unpost', 'archived-many', 'column', 'filter'].map((u) => {
      if (url.includes(u)) {
        window.dispatchEvent(new CustomEvent('showLoading', { detail: message }));
      }
    })
    return next.handle(headers).pipe(
      tap(response => {
        if (!url.join("").includes('analytics.loadboardnetwork.com')) {
          if(response instanceof HttpResponse && response.ok) return window.dispatchEvent(new CustomEvent('response', { detail: response }));

          if (response instanceof HttpResponse && !response.body.status) return window.dispatchEvent(new CustomEvent('responseError', { detail: response }));

          if (response instanceof HttpResponse && response.body.status) return window.dispatchEvent(new CustomEvent('response', { detail: response }));
        }
      }),
      catchError((error: HttpErrorResponse) => {
        window.dispatchEvent(new CustomEvent('error', { detail: error }));
        return throwError(error);
      }),
      finalize(() => {
        window.dispatchEvent(new CustomEvent('hideToast'));
        window.dispatchEvent(new CustomEvent('hideLoading'));
      }))
  }
}