export function asyncLoading() {
  return function (target: Object, propertyKey: string, descriptor: PropertyDescriptor) {
    const oldValue = descriptor.value;

    descriptor.value = async function (...args) {
      window.dispatchEvent(new Event('showLoading'));
      try {
        const result = await oldValue.apply(this, args);
        return result;
      } catch (error) {
        console.log(error);
      } finally {
        window.dispatchEvent(new Event('hideLoading'));
      }
    }

    return descriptor;
  }
}