import { Component, Input, NgModule, OnInit } from '@angular/core';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {
  @Input() size: string;

  constructor() { }

  ngOnInit(): void {
  }
}

@NgModule({
  declarations: [LogoComponent],
  imports: [],
  exports: [LogoComponent]
})

export class LogoModule { }