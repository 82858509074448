import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(private http: HttpClient) { }

  getZipcodes(searchTerm: string): Observable<any> {
    return this.http.get(`${environment.url}/utility/zip-codes/${searchTerm}`)
      .pipe(map((res: any) => res.data?.result?.zipCodes));
  }

  getCompanyInfo(companyId: string): Observable<any> {
    return this.http.get(`${environment.url}/company/${companyId}`)
      .pipe(map((res: any) => res.data))
  }

  getEquipmentTypes(): Observable<any> {
    return this.http.get(`${environment.url}/utility/equipmentType`)
      .pipe(map((res: any) => res.data))
  }

  getEnumsValue(enumName: 'EQUIPMENT_NAMES' | 'PAYMENT_TERMS'): Observable<any> {
    return this.http.get(`${environment.url}/utility/enums/${enumName}`)
      .pipe(map((res: any) => {
        const literalObj = {
          EQUIPMENT_NAMES: () => Object.keys(res.data?.result).map((key) => res.data?.result[key]),
          PAYMENT_TERMS: () => Object.keys(res.data?.result).map((key) => res.data?.result[key])
        }
        return literalObj[enumName]();
      }));
  }

  /**
   * Method to copy a value to clipboard
   * @param value
   */
  copyHtmlElementValue(value: string) {
    let selector = document.createElement('textarea');
    selector.style.position = 'fixed';
    selector.value = value;
    document.body.appendChild(selector);
    selector.focus();
    selector.select();
    document.execCommand('copy');
    document.body.removeChild(selector);
  }

  /**
  * remove text accent
  * @param text 
  * @returns 
  */
  removeAccent(text: string) {
    if (!text) return text
    return text
      .normalize('NFD')
      .replace(
        /([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,
        '$1'
      )
      .normalize();
  };
}
