import * as _ from 'lodash';

export type TSortBy = 'asc' | 'desc' | 'no-sort';

export class SortBy {
    private indexSorting: TSortBy[] = ['no-sort', 'asc', 'desc'];
    sortingHeader: Record<string, string> = {
        'posted': 'no-sort',
        'importRef': 'no-sort',
        'consignee.city': 'no-sort',
        'consignee.state': 'no-sort',
        'shipper.city': 'no-sort',
        'shipper.state': 'no-sort',
        'appointment.pickup.from.date': 'no-sort',
        'loadInfo.equipmentType': 'no-sort',
        'loadInfo.partial': 'no-sort',
        'loadInfo.length': 'no-sort',
        'loadInfo.weight': 'no-sort',
        'loadInfo.quantity': 'no-sort',
        'namePostedBy': 'no-sort',
        'nameEnterBy': 'no-sort',
        'userId.businessUnit': 'no-sort',
        'star': 'no-sort'
    }
    sortingWebsite = {
        'isActiveMyUser': 'no-sort',
        'websiteName': 'no-sort',
        'free': 'no-sort',
        'brokers[0].websiteUserName': 'no-sort',
        'brokers[0].websitePassword': 'no-sort'
    }
    constructor() { }

    private resolvePath = (object, path, defaultValue) => path.split('.').reduce((o, p) => o ? o[p] : defaultValue, object);

    private sortByString: Record<TSortBy, Function> = {
        'asc': (a, b, propertyPath) => this.resolvePath(a, propertyPath, a).localeCompare(this.resolvePath(b, propertyPath, b)),
        'desc': (a, b, propertyPath) => this.resolvePath(b, propertyPath, b).localeCompare(this.resolvePath(a, propertyPath, a)),
        'no-sort': () => 0,
    }

    private sortByNumber: Record<TSortBy, Function> = {
        'asc': (a, b, propertyPath) => this.resolvePath(a, propertyPath, a) - this.resolvePath(b, propertyPath, b),
        'desc': (a, b, propertyPath) => this.resolvePath(a, propertyPath, a) + this.resolvePath(b, propertyPath, b),
        'no-sort': () => 0,
    }
    /**
     * Sorted by backend
     * @deprecated
     */
    newSortBy(propertyPath) {
        let values = [0, 1, -1];

        let index = this.indexSorting.findIndex((sort) => sort === this.sortingHeader[propertyPath]) + 1;

        this.sortingHeader[propertyPath] = index !== this.indexSorting.length ? this.indexSorting[index] : this.indexSorting[0];

        return values[index];
    }

    sortBy(propertyPath: string, value: Array<any>, stay: boolean = false): { sortArray: Array<any>, typeSort: string } {
        let index = this.indexSorting.findIndex((sort) => sort === this.sortingHeader[propertyPath]) + 1;

        let typeValue = typeof this.resolvePath(value[0], propertyPath, value[0]);

        if (!stay) this.sortingHeader[propertyPath] = index !== this.indexSorting.length ? this.indexSorting[index] : this.indexSorting[0];

        let typeSort = this.sortingHeader[propertyPath];

        if (typeSort == 'no-sort') return { sortArray: value, typeSort };

        let lastArray = typeValue == 'string' ? value.sort((a, b) => this.sortByString[typeSort](a, b, propertyPath)) : value.sort((a, b) => this.sortByNumber[typeSort](a, b, propertyPath));

        return { sortArray: lastArray, typeSort }
    }

    newSortByLodash(propertyPath, value, stay) {
        let index = this.indexSorting.findIndex((sort) => sort === this.sortingHeader[propertyPath]) + 1;

        if (!stay) this.sortingHeader[propertyPath] = index !== this.indexSorting.length ? this.indexSorting[index] : this.indexSorting[0];

        let typeSort = this.sortingHeader[propertyPath];

        if (typeSort == 'no-sort') return { sortArray: value, typeSort };

        let keys = Object.keys(this.sortingHeader).filter((key) => this.sortingHeader[key] !== 'no-sort');
        let values = Object.values(this.sortingHeader).filter(value => value !== 'no-sort');
        let arrayValue = [...values]

        let lastArray = _.orderBy(value, [...keys], <'asc' | 'desc'><unknown>arrayValue)

        return { sortArray: lastArray, typeSort }
    }

    sortByWebsites(propertyPath, value, stay) {
        let index = this.indexSorting.findIndex((sort) => sort === this.sortingWebsite[propertyPath]) + 1;

        if (!stay) this.sortingWebsite[propertyPath] = index !== this.indexSorting.length ? this.indexSorting[index] : this.indexSorting[0];

        let typeSort = this.sortingWebsite[propertyPath];

        if (typeSort == 'no-sort') return { sortArray: value, typeSort };

        let keys = Object.keys(this.sortingWebsite).filter((key) => this.sortingWebsite[key] !== 'no-sort');
        let values = Object.values(this.sortingWebsite).filter(value => value !== 'no-sort');

        let arrayValue = [...values]

        let lastArray = _.orderBy(value, [...keys], <'asc' | 'desc'><unknown>arrayValue)

        return { sortArray: lastArray, typeSort }
    }
}