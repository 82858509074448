<div class="flex h-full w-full">
    <!-- Column 1 -->
    <div class="flex flex-col items-start justify-between bg-white px-16 py-10 w-full lg:px-20 lg:py-16 lg:w-1/3">
        <app-logo size="lg"></app-logo>

        <div [formGroup]="loginForm" class="w-full -mt-10">
            <div class="text-black text-lg font-semibold">Welcome,</div>
            <div class="text-gray-400 text-md font-semibold">Sign in to start booking shipments.</div>

            <div class="flex flex-col gap-3 my-8">
                <input formControlName="username" type="text" id="email" placeholder="Username"
                    class="input placeholder-gray-700" (keyup.enter)="login()">
                <input formControlName="password" type="password" id="password" placeholder="Password"
                    class="input placeholder-gray-700" (keyup.enter)="login()">
                <label class="cursor-pointer text-xs font-medium text-right text-gray-400"
                    (click)="goToForgotPassword()">Forgot your password?</label>
            </div>

            <div class="flex flex-row gap-2 mt-4">
                <button class="btn-primary w-full lg:w-1/2" (click)="login()">
                    Sign In
                </button>
                <button class="btn-dark-outline w-full lg:w-1/2" routerLink="/register">
                    Free Sign Up
                </button>
            </div>
        </div>

        <div class="invisible">lorem</div>
    </div>

    <!-- Column 2 -->
    <div class="column2 flex-col justify-between text-white hidden lg:flex lg:w-2/3">
        <!-- <header class="flex justify-between bg-transparent text-white text-xs px-20 py-6 w-full mt-4">
            <div class="cursor-pointer font-semibold">Home</div>
            <div class="cursor-pointer font-semibold">TMS Partners</div>
            <div class="cursor-pointer font-semibold">Load Boards</div>
            <div class="cursor-pointer font-semibold">Posting Solutions</div>
            <div class="cursor-pointer font-semibold">Support</div>
        </header> -->

        <!-- <footer class="flex justify-between bg-black text-white text-xs px-20 py-6 w-full">
            <div class="cursor-pointer font-semibold">Increase Freight Visibility</div>
            <div class="cursor-pointer font-semibold">Reduce Data Entry</div>
            <div class="cursor-pointer font-semibold">Receive Better Rates</div>
        </footer> -->
    </div>
</div>