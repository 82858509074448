import { Injectable, TemplateRef } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    constructor(public snackbar: MatSnackBar) { }

    private panelClassFromType = {
        error: 'toast-error',
        success: 'toast-success',
        default: ''
    }

    showToast(
        message: string,
        horizontalPosition?: MatSnackBarHorizontalPosition,
        verticalPosition?: MatSnackBarVerticalPosition,
        accion?: any,
        duration = 3000,
        type: 'success' | 'error' | 'default' = 'default') {
        this.snackbar.open(message, accion, {
            duration,
            horizontalPosition,
            verticalPosition,
            panelClass: this.panelClassFromType[type] ? ['text-sm', this.panelClassFromType[type]] : 'text-sm'
        });
    }

    hideToast() {
        this.snackbar.dismiss()
    }
}
