<section class="w-full h-5/6">
    <!-- Filters -->
    <!-- <section class="transition-all duration-200" [ngClass]="{ 'hidden': !filterActive }">
        <header
            class="w-full grid grid-cols-10 justify-items-center py-4 text-xs whitespace-nowrap gap-4"
            id="header-filter" #filterHeader [ngClass]="{
                'few_list': tableColumns.length < 10,
                'many_list': tableColumns.length > 10
            }">
            <span *ngFor="let filter of tableColumns; index as i" class="font-semibold flex items-center">
                <input [type]="text" [value]="filters[i]" class="input"
                    *ngIf="!['', 'Posted', 'Load ID', 'Modified', 'Pickup', 'Starred', 'Delivery Date'].includes(filter)"
                    (keyup.enter)="setFilterEnterPress(i, $event)">
                <input *ngIf="['Pickup','Modified', 'Delivery Date'].includes(filter)"
                    (dateChange)="searchDate(i, $event)" class="input" [matDatepicker]="deliveryDatePicker"
                    (click)="deliveryDatePicker.open()">
                <mat-datepicker #deliveryDatePicker></mat-datepicker>
                <i class="far fa-times-circle cursor-pointer pl-0.5"
                    *ngIf="!['', 'Posted', 'Load ID', 'Starred'].includes(filter)" (click)="removeFilter(i)"></i>
            </span>
        </header>
    </section> -->
    <!-- Columns -->
    <header
        class="grid grid-cols-10 justify-items-center border-b-4 border-[#F5F5F5] py-4 bg-dxf-blue text-white text-xs whitespace-nowrap rounded-t-lg">
        <span class="font-semibold flex items-center" *ngFor="let headers of tableColumns; index as i">
            <i class="fas fa-filter cursor-pointer pr-0.5" *ngIf="i > 1 && filterActive"
                (click)="setFilterHeader(headers, i)"></i>
            <p class="headers">{{ headers }}
            </p>
            <!-- <i class="fa-solid fa-sort-down pl-0.5" *ngIf="sorted[i] == 'desc'"></i>
            <i class="fa-solid fa-sort-up pl-0.5" *ngIf="sorted[i] == 'asc'"></i> -->
        </span>
    </header>
    <!-- Body -->
    <cdk-virtual-scroll-viewport appendOnly itemSize="50" class="h-4.5/5">
        <div *cdkVirtualFor="let shipment of (shipments$ | async)?.data?.docs"
            class="grid justify-items-center h-full border-b-2 border-[#F5F5F5] py-2 text-xs grid-cols-10">
            <ng-container *ngFor="let header of tableColumns">
                <div *ngIf="header && headerPropertys[header]">
                    {{
                    !['Modified', 'Pickup'].includes(header) ?
                    shipment[headerPropertys[header]] :
                    (shipment[headerPropertys[header]] | date:'shortDate' )
                    }}
                </div>
            </ng-container>
        </div>
        <div *ngIf="(shipments$ | async)?.data?.docs.length === 0" class="text-center text-sm w-full mt-5">
            No Shipments
        </div>
    </cdk-virtual-scroll-viewport>
    <footer class="border-t">
        <mat-paginator class="mr-28" [pageSizeOptions]="[25,50,100,250,500]"
            [length]="(shipments$?.paginator | async)?.totalDocs" [pageSize]="(shipments$?.paginator | async)?.limit"
             aria-label="Select page">
             <!-- (page)="paginator($event)" -->
        </mat-paginator>
    </footer>
</section>