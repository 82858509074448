import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';

@Component({
  selector: 'error-field',
  templateUrl: './error-field.component.html'
})
export class ErrorFieldComponent{
  @Input() text: string;
  
  constructor() { }
}

@NgModule({
  declarations: [ErrorFieldComponent],
  imports: [CommonModule],
  exports: [ErrorFieldComponent]
})

export class ErrorFieldModule { }