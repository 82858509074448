import { BehaviorSubject, Observable } from 'rxjs';
import { SortBy } from './sort.util';
import { ShipmentService } from '../services/shipment/shipment.service'
import { map, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { environment } from '../environments/environment'
import { io } from "socket.io-client";

export class ActiveStore {
    shipments: BehaviorSubject<Array<any>>;
    paginator: Promise<{}>;
    typeSort: string;
    private sort: SortBy = new SortBy();
    private itsSortBy: Set<string> = new Set();
    private sortValues = {}
    private filters = {}
    constructor(private _init_shipments: Observable<IActiveList>, private shipmentService: ShipmentService) {
        this.shipments = this.convertIntoBehavior(_init_shipments);
        // this.paginator = this.getPaginator(_init_shipments);
        // this._init_shipments = this.convertIntoBehavior(_init_shipments);
        this.getEventSource();
        this.getEventFromNode();
    }

    private getEventSource() {
        let source = new EventSource(`https://queue.loadboardnetwork.com/api/shipment/see/event-postload-count-shipment?token=${localStorage.getItem('token')}`)
        source.addEventListener('message', ({ data }) => {
            const posted = JSON.parse(data).data;

            this.update('posted-updated', posted)
        });

        source.addEventListener('error', (e) => { console.log(e) });
    }

    private getEventFromNode() {
        let messages = {
            'NegotiationCreated': 'New bid received',
            'NegotiationCounteroffer': 'Counteroffer received',
            'NegotiationDeclined': 'Bid Decline',
            'NegotiationAccepted': 'Load accepted',
            'ShipmentAccepted': 'Load accepted',
            'ShipmentReadyForPickup': 'Load booked and ready for pick up',
            'ShipmentDelivered': 'Load delivered.'
        }

        let socket = io(`${environment.socketUrl}`);
        
        socket.on(`notification-shipment${localStorage.getItem('_id')}`, (ev) => {
            console.log(ev)
            let shipment = {
                ...ev.data,
                text: messages[ev.message],
                showBid: ['NegotiationCreated'].includes(ev.message)
            }

            this.update('notification', shipment)
        })
    }

    private convertIntoBehavior(data: Observable<any>): BehaviorSubject<any> {
        let bs = new BehaviorSubject([]);

        let sub = data.pipe(tap(value => {
            this.paginator = new Promise((d) => d(value.data));
        }), map(value => value.data.docs)).subscribe((value) => bs.next(value));

        return bs;
    }

    private async getPaginator(data: Observable<any>): Promise<Object> {
        let sub = await data.pipe(map(v => v.data)).toPromise();

        delete sub.docs;

        return sub;
    }

    set filter(obj) {
        this.filters = { ...obj }
    }

    get sortValue() {
        return { sortField: Object.keys(this.sortValues).join(), sortValue: Object.values(this.sortValues).join() }
    }

    updatedPaginator({ pageIndex, pageSize }) {
        this.shipmentService.newGetShipment({ ...this.sortValue, page: pageIndex + 1, limit: pageSize }, this.filters).toPromise();
    }

    sortBy(propertyPath: string, stay: boolean = false, shipment?: Array<any>) {
        // SORT BY BACKEND
        let sortValue = this.sort.newSortBy(propertyPath);

        if (!sortValue) {
            delete this.sortValues[propertyPath];

            this.typeSort = undefined;

            return this.shipmentService.newGetShipment({ ...this.sortValue, limit: 500, page: 1 }, { ...this.filters }).toPromise();
        }

        this.sortValues[propertyPath] = sortValue

        this.shipmentService.newGetShipment({ ...this.sortValue, limit: 500, page: 1 }, { ...this.filters }).toPromise();

        this.typeSort = sortValue === 1 ? 'asc' : 'desc';

        // SORT BY Front
        // const shipments = shipment && shipment.length > 0 ? [...shipment] : [...this.shipments.value];

        // const { sortArray, typeSort } = this.sort.newSortByLodash(propertyPath, shipments, stay);

        // if (typeSort !== 'no-sort') {
        //     this.itsSortBy.add(propertyPath)
        // } else {
        //     this.itsSortBy.delete(propertyPath)
        // }

        // this.typeSort = typeSort;
        // this.shipments.next([...sortArray]);
    }

    reset(): void {
        this.shipments = this.convertIntoBehavior(this._init_shipments)
    }

    update(url: string, data) {
        const actionEdit = url.split(/[\/=]/gm);

        if (actionEdit.includes('filter?limit')) {
            if (this.itsSortBy.size > 0) {
                this.itsSortBy.forEach((sort) => {
                    this.paginator = new Promise((d, r) => d(data));
                    return this.sortBy(sort, true, data.docs)
                })
            } else {
                this.shipments.next([...data.docs]);
                this.paginator = new Promise((d, r) => d(data));
            }
        }
        if (actionEdit.includes('sortField') && actionEdit[actionEdit.findIndex((url) => url === 'sortField') + 1] !== 'undefined') {
            this.shipments.next([...data.docs])
        }
        if (actionEdit.includes('favorite')) {
            const active: Array<any> = this.shipments.value;

            const index = active.findIndex((ship) => ship._id == data._id);

            active[index] = {
                ...data
            }

            this.shipments.next([
                ...active
            ]);
        }
        if (actionEdit.includes('shipment?post')) {
            let active: Array<any> = this.shipments.value;
            this.paginator.then((p) => p['totalDocs'] = p['totalDocs'] + 1);

            active.unshift({
                ...data,
                posted: actionEdit.includes('true') ? 'Yes' : 'No'
            });

            this.shipments.next([
                ...active,
            ]);
        }
        if (actionEdit.includes('edit-many') ||
            actionEdit.includes('update-pickup-shipment') ||
            actionEdit.includes('post-loads') ||
            actionEdit.includes('unpost')) {
            const active: Array<any> = this.shipments.value;

            data.data.forEach(load => {
                const index = active.findIndex((ship) => ship._id == load._id);
                if (index >= 0) {
                    active[index] = {
                        ...load,
                        posted: actionEdit.includes('unpost') || (actionEdit.includes('edit-many') && !data.posting) ? 'No' : 'Yes'
                    }

                    if (this.filters['posted']) {
                        // active.splice(index, 1);
                    }
                }
            });

            if (this.filters['posted']) {
                this.updatedPaginator({ pageIndex: 0, pageSize: 25 })
            }

            this.shipments.next([...active]);
        }
        if (actionEdit.includes('import-load-csv')) {
            const active: Array<any> = this.shipments.value;

            if (Object.keys(this.filters).length === 0) {
                if (data.shipments.length > 0) {
                    let newShips = [];

                    data.shipments.map(newShip => {
                        let index = active.findIndex((oldShip) => oldShip.importRef === newShip.importRef);
                        if (index !== -1) {
                            active[index] = {
                                ...active[index],
                                ...newShip
                            }
                        } else {
                            newShips.push(newShip);
                        }
                    })
                    this.paginator.then((p) => p['totalDocs'] = p['totalDocs'] + newShips.length);

                    newShips.length > 0 && active.unshift(...newShips)

                    this.shipments.next([
                        ...active,
                    ]);
                    return Swal.fire('Succes', data.shipments.map(v => `Load ID: ${v.importRef} <br>`).join(" "), 'success').then((v) => {
                        if (data.errors.length > 0) {
                            return Swal.fire('Error', data.errors.map(v => `Load ID ${v.importRef}: ${v.error} <br>`).join(" "), 'error')
                        }
                    })
                }
                if (data.errors.length > 0) {
                    return Swal.fire('Error', data.errors.map(v => `${v.importRef ? 'Load ID: ' : ''} ${v.error} <br>`).join(" "), 'error')
                }
            }
        }
        if (actionEdit.includes('archived-many') && actionEdit.includes('archived')) {
            const active: Array<any> = this.shipments.value
            this.paginator.then((p) => p['totalDocs'] = p['totalDocs'] - data.data.length);

            data.data.map(loads => {
                let index = active.findIndex((ship) => ship.importRef == loads.importRef);

                active.splice(index, 1);

                return active;
            });

            data.added.map(loads => {
                active.push(loads)
            })

            this.shipments.next([
                ...active,
            ]);
        }
        if (actionEdit.includes('archived-many') && actionEdit.includes('active')) {
            const active: Array<any> = this.shipments.value;
            this.paginator.then((p) => p['totalDocs'] = p['totalDocs'] + data.data.length);

            data.data.forEach(load => {
                active.unshift({ ...load });
            });

            this.shipments.next([
                ...active,
            ]);
        }
        if (actionEdit.includes('duplicate')) {
            const actives: Array<any> = this.shipments.value;

            data.data.forEach(load => {
                if (!load.favorite) {
                    load.posted = data.isPosting ? "Yes" : "No";

                    actives.unshift({ ...load });
                    this.paginator.then((p) => p['totalDocs'] = p['totalDocs'] + 1);
                }
            });
            this.shipments.next([
                ...actives,
            ]);
        }
        if (actionEdit.includes('add')) {
            if (Object.keys(this.filters).length === 0) {
                this.paginator.then((p) => p['totalDocs'] = p['totalDocs'] + data.length);
                this.shipments.next([
                    data,
                    ...this.shipments.value
                ])
            }
        }
        if (actionEdit.includes('create-many?post') || actionEdit.includes('add-many')) {
            const active: Array<any> = this.shipments.value
            if (Object.keys(this.filters).length === 0) {
                this.paginator.then((p) => p['totalDocs'] = p['totalDocs'] + data.data.length);
                let shipPosted = data.data.map(ship => {
                    return {
                        ...ship,
                        posted: actionEdit.includes('true') ? 'Yes' : 'No'
                    }
                })


                let actives = [...shipPosted, ...active];

                this.shipments.next([...actives])
            }
        }
        if (actionEdit.includes('posted-updated')) {
            const actives: Array<any> = this.shipments.value;

            let index = actives.findIndex((ship) => ship._id === data._id);

            actives[index].posted = data.posted;

            this.shipments.next([
                ...actives
            ])
        }
        if (actionEdit.includes('notification') || actionEdit.includes('read-by-shipment')) {
            const actives: Array<any> = this.shipments.value;

            let index = actives.findIndex((ship) => ship._id === data._id);

            actives[index] = { ...data };


            this.shipments.next([
                ...actives
            ])
        }

        if (actionEdit[actionEdit.length - 2] === 'shipment' && !['unpost', 'post-loads', 'posting-loadboard', 'unpost-loadboard', 'create-many', 'favorite-many', 'delete-archive', 'edit-many'].includes(actionEdit[actionEdit.length - 1])) {
            const actives: Array<any> = this.shipments.value;

            let index = actives.findIndex((ship) => ship._id === data._id);

            actives[index] = data;

            this.shipments.next([
                ...actives
            ])
        }
    }
}

export interface IActiveList {
    docs: Array<any>
    hasNextPage: boolean
    hasPrevPage: boolean
    limit: number
    nextPage: number
    page: number
    pagingCounter: number
    prevPage: number
    totalDocs: number
    totalPages: number
}