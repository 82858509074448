import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { AuthService, ShipmentService, ToastService, UtilsService } from "@services";
import { environment } from '../../../environments/environment';
@Component({
  selector: 'generate-iframe-link',
  templateUrl: './generate-iframe-link.component.html'
})
export class GenerateIframeLinkComponent implements OnInit {
  generateIframeLink: string;
  url: SafeResourceUrl;
  constructor(private _sanitizer: DomSanitizer, private authService: AuthService, private _toastsService: ToastService, private _utilsService: UtilsService) { }

  async ngOnInit(): Promise<void> {
    let token = await this.generateCompanyToken();
    let url = `https://${environment.production ? 'app' : 'dev'}.loadboardnetwork.com/#/personal-loads/${token}`;


    this.generateIframeLink = `<iframe src="${url}" scrolling="no" frameborder="1" height="400px" width="100%" allowfullscreen></iframe>`;
    this.url = this._sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  async generateCompanyToken() {
    try {
      const res = await this.authService.generateCompanyToken().toPromise();

      return res?.data?.accessToken
    } catch (error) {
      console.log(error);
    }
  }

  copyIframeLink() {
    this._utilsService.copyHtmlElementValue(this.generateIframeLink);
    this._toastsService.showToast('Personal load boards iframe link copied!');
  }

  copyPostmanCollection() {
    const a = document.createElement('a');
    a.href = `${environment.url}/shipment/export/postman?token=${localStorage.token}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}