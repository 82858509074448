import { Injectable } from '@angular/core'
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ReportsService {
    constructor(private http: HttpClient) { }

    getUsersWithPhoneMismatched() {
        return this.http.get(`${environment.analyticsAPI}/reports/users_with_phone_mismatched`, {
            observe: 'body',
            responseType: 'blob'
        })
    }

    getUsersByLoadBoardActivity() {
        return this.http.get(`${environment.analyticsAPI}/reports/users_by_loadboard_activity`, {
            observe: 'body',
            responseType: 'blob'
        })
    }

    getLoadsByLoadBoardActivity(loadboardId: string) {
        const params = new HttpParams().set('loadboardId', loadboardId)
        return this.http.get(`${environment.analyticsAPI}/reports/loads_by_loadboards_activity`, {
            params,
            observe: 'response',
            responseType: 'blob'
        })
    }

    getUsersByCompany(fromDate: string) {
        const params = new HttpParams().set('date_from', fromDate)
        return this.http.get(`${environment.analyticsAPI}/reports/users_by_companies`, {
            params: fromDate ? params : {},
            observe: 'body',
            responseType: 'blob'
        })
    }

    getLoadCountByLoadboards(fromDate: string, toDate: string) {
        const params = new HttpParams().set('date_from', fromDate).set('date_to', toDate);
        return this.http.get(`${environment.analyticsAPI}/reports/loads_counts_by_loadboards`, {
            params,
            observe: 'body',
            responseType: 'blob'
        })
    }

    getLoadCountByDates(fromDate: string, toDate: string, action: string) {
        const params = new HttpParams().set('date_from', fromDate).set('date_to', toDate).set('filter_by', action);
        return this.http.get(`${environment.analyticsAPI}/reports/loads_by_dates`, {
            params,
            observe: 'body',
            responseType: 'blob'
        })
    }

    getListOfCompanies(fromDate: string) {
        const params = new HttpParams().set('date_from', fromDate);
        return this.http.get(`${environment.analyticsAPI}/reports/list_of_companies`, {
            params: fromDate ? params : {},
            observe: 'body',
            responseType: 'blob'
        })
    }

    getLastPostedByCompany() {
        return this.http.get(`${environment.analyticsAPI}/reports/admins_by_companies`, {
            observe: 'body',
            responseType: 'blob'
        })
    }

    getLastPostedByUser() {
        return this.http.get(`${environment.analyticsAPI}/reports/admins_users_by_companies`, {
            observe: 'body',
            responseType: 'blob'
        })
    }

    getLoadsByCompanies({ from, to }){
        let params = new HttpParams().appendAll({
            'date_from': from,
            'date_to': to
        })
        
        return this.http.get(`${environment.analyticsAPI}/reports/loads_counts_by_companies`, {
            params,
            observe: 'body',
            responseType: 'blob'
        })
    }

    getTMSIntegration(){
        return this.http.get(`${environment.url}/company/export/companies-tms/sa?token=${localStorage.getItem('token')}`, {
            observe: 'body',
            responseType: 'blob'
        })
    }
}