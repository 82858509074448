import { Component, NgModule } from '@angular/core';
import { LogoModule } from '@utils-components';
@Component({
  selector: 'register-topbar',
  templateUrl: './register-topbar.component.html'
})
export class RegisterTopbarComponent {
  constructor() { }
}

@NgModule({
  imports: [LogoModule],
  declarations: [RegisterTopbarComponent],
  exports: [RegisterTopbarComponent]
})
export class RegisterTopbarModule { }