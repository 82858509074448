import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private http: HttpClient) { }

  getCompany(id): Observable<any> {
    return this.http.get(`${environment.url}/company/${id}`);
  }

  saveCompany(id, company): Observable<any> {
    return this.http.put(`${environment.url}/company/${id}`, company);
  }
  /** 
   * @deprecated
  */
  getUsersOfCompany(): Observable<any> {
    return this.http.get(`${environment.url}/company/all/users`);
  }

  getTypeOfBusiness(): Observable<any> {
    return this.http.get(`${environment.url}/company/type/business`)
  }

  uploadLogo(photo: any): Observable<any> {
    return this.http.post(`${environment.url}/company/upload-logo`, photo)
  }

  getAllUsers({ dato = '', sortField = '', sortDir = '' }): Observable<any> {
    let params = new HttpParams().set('dato', dato).set('sortField', sortField).set('sortDir', sortDir);
    return this.http.get(`${environment.url}/lbn/user/all/company`, {
      params
    });
  }

  getCompanies(query: { limit?: number, page?: number, dato?: string }): Observable<any> {
    let params = new HttpParams().set('limit', query.limit || 10).set('page', query.page || 1).set('dato', query.dato || '');

    return this.http.get(`${environment.url}/lbn/company/find-all/sa`, {
      params
    });
  }

  updateCompanyStatus({ companyId, status }) {
    return this.http.put(`${environment.url}/company/update-status/${companyId}/sa`, {
      status
    })
  }
  updateNightlyPurge({ companyId, nightlyPurge }) {
    return this.http.put(`${environment.url}/company/update-enightly-purge/${companyId}/sa`, {
      nightlyPurge
    })
  }

  getQeueNumber() {
    return this.http.get(`${environment.url}/company/get-queue-number/sa`)
  }

  createCompany(company) {
    return this.http.post(`${environment.url}/auth/create-account-sa/sa`, {
      ...company
    })
  }

  editCompany({ _id, ...restCompany }): Observable<any> {
    return this.http.put(`${environment.url}/lbn/company/${_id}/sa`, {
      ...restCompany
    })
  }

  integrateTMS(id: string, serviceKey: string) {
    return this.http.put(`${environment.url}/company/update-tms/${id}/sa`, { serviceKey: serviceKey })
  }
}