import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private router: Router,
    private http: HttpClient) { }

  createUser(user) {
    return this.http.post(`${environment.url}/auth/create-account`, user);
  }

  login(user: User): Observable<any> {
    return this.http.post(`${environment.url}/auth/login`, user);
  }

  forgotPassword(email: string): Observable<any> {
    return this.http.post(`${environment.url}/auth/forgot-password`, { username: email })
  }

  changePassword(password: string, token: string): Observable<any> {
    return this.http.post(`${environment.url}/auth/change/password`, { password, token })
  }

  verifyAccount(token): Observable<any> {
    return this.http.post(`${environment.url}/auth/verify/account`, token)
  }

  generateCompanyToken(): Observable<any> {
    return this.http.post(`${environment.url}/auth/company-token`, {})
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/login'])
  }

  loginDashboard(userId: string, companyId: string) {
    const params = new HttpParams().set('userId', userId)
      .set('companyId', companyId)
      .set('database', environment.production ? 'production' : 'development')
    return this.http.get(`${environment.analyticsAPI}/dashboard/login_dashboard`, {
      params,
    })
  }

  loginReports(userId: string, companyId: string): Observable<{ token: string }> {
    return this.http.post<{ token: string }>(`${environment.analyticsAPI}/reports/user/authentication`, {
      userId,
      companyId,
      database: environment.production ? 'production' : 'development'
    })
  }
}

interface User {
  username: string;
  password: string;
}
