import { BehaviorSubject, Observable } from 'rxjs';
import { SortBy } from './sort.util';
import { ShipmentService } from '../services/shipment/shipment.service'
import { map, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';


export class ArchiveStore {
    shipments: BehaviorSubject<Array<any>>;
    paginator: Promise<{}>;
    typeSort: string;
    private sort: SortBy = new SortBy();
    private itsSortBy: Set<string> = new Set();
    private sortValues = {}
    private filters = {}
    constructor(private _init_shipments: Observable<any>, private shipmentService: ShipmentService) {
        this.shipments = this.convertIntoBehavior(_init_shipments);
        // this.paginator = this.getPaginator(_init_shipments);
        // this._init_shipments = this.convertIntoBehavior(_init_shipments);

    }

    private convertIntoBehavior(data: Observable<any>): BehaviorSubject<any> {
        let bs = new BehaviorSubject([]);

        let sub = data.pipe(tap(value => {
            this.paginator = new Promise((d) => d(value.data));
        }), map(value => value.data.docs)).subscribe((value) => bs.next(value));

        return bs;
    }

    private async getPaginator(data: Observable<any>): Promise<Object> {
        let sub = await data.pipe(map(v => v.data)).toPromise();

        delete sub.docs;

        return sub;
    }

    set filter(obj) {
        this.filters = { ...obj }
    }

    updatedPaginator({ pageIndex, pageSize }) {
        this.shipmentService.getShipmentArchive({ sortField: Object.keys(this.sortValues).join(), sortValue: Object.values(this.sortValues).join(), page: pageIndex + 1, limit: pageSize }).toPromise();
    }

    sortBy(propertyPath: string, stay: boolean = false, shipment?: Array<any>) {
        // SORT BY BACKEND
        let sortValue = this.sort.newSortBy(propertyPath);

        if (!sortValue) {
            delete this.sortValues[propertyPath];

            this.typeSort = undefined;

            return this.shipmentService.getShipmentArchive({ sortField: Object.keys(this.sortValues).join(), sortValue: Object.values(this.sortValues).join(), limit: 500, page: 1 }).toPromise();
        }

        this.sortValues[propertyPath] = sortValue

        this.shipmentService.getShipmentArchive({ sortField: Object.keys(this.sortValues).join(), sortValue: Object.values(this.sortValues).join(), limit: 500, page: 1}).toPromise();

        this.typeSort = sortValue === 1 ? 'asc' : 'desc';

        //SORT BY FRONT

        // const shipments = shipment && shipment.length > 0 ? [...shipment] : [...this.shipments.value];

        // const { sortArray, typeSort } = this.sort.newSortByLodash(propertyPath, shipments, stay)

        // const shipments = shipment && shipment.length > 0 ? [...shipment] : [...this.shipments.value];

        // const { sortArray, typeSort } = this.sort.sortBy(propertyPath, shipments, stay);

        // if (typeSort !== 'no-sort') {
        //     this.itsSortBy.add(propertyPath)
        // } else {
        //     this.itsSortBy.delete(propertyPath)
        // }

        // this.typeSort = typeSort;
        // this.shipments.next([...sortArray]);
        // this.typeSort = typeSort;

        // this.shipments.next([...sortArray]);
    }

    reset(): void {
        this.shipments = this.convertIntoBehavior(this._init_shipments)
    }

    update(url: string, data) {
        const actionEdit = url.split(/[\/=]/gm);

        if (actionEdit.includes('archived?limit')) {
            if (this.itsSortBy.size > 0) {
                this.itsSortBy.forEach((sort) => {
                    this.paginator = new Promise((d, r) => d(data));
                    return this.sortBy(sort, true, data.docs)
                })
            } else {
                this.shipments.next([...data.docs]);
                this.paginator = new Promise((d, r) => d(data));
            }
        }

        if (actionEdit.includes('delete-archive')) {
            const archives: Array<any> = this.shipments.value;
            this.paginator.then((p) => p['totalDocs'] = p['totalDocs'] - data.data.length);

            data.data.forEach(load => {
                const index = archives.findIndex((ship) => ship._id == load._id);
                archives.splice(index, 1);
            });

            data.added.map(loads => {
                archives.push(loads)
            })

            this.shipments.next([
                ...archives,
            ]);
        }

        if (actionEdit.includes('archived-many') && actionEdit.includes('archived')) {
            const archives: Array<any> = this.shipments.value;
            this.paginator.then((p) => p['totalDocs'] = p['totalDocs'] + data.data.length);

            data.data.forEach(load => {
                archives.unshift({ ...load });
            });

            this.shipments.next([
                ...archives,
            ]);
        }

        // if (actionEdit.includes('archived-many') && actionEdit.includes('active')) {
        //     const archive: Array<any> = this.shipments.value

        //     data.data.map(loads => {
        //         let index = archive.findIndex((ship) => ship.importRef == loads.importRef);

        //         archive.splice(index, 1);

        //         return archive;
        //     });

        //     this.shipments.next([
        //         ...archive,
        //     ]);
        // }

        if (actionEdit.includes('edit-many') ||
            actionEdit.includes('update-pickup-shipment') ||
            actionEdit.includes('post-loads')) {
            const archive: Array<any> = this.shipments.value;

            data.data.forEach(load => {
                const archiveIndex = archive.findIndex((ship) => ship._id == load._id);
                if (archiveIndex >= 0) {
                    archive[archiveIndex] = {
                        ...load
                    }
                }
            });

            this.shipments.next([
                ...archive,
            ]);
        }
        if (actionEdit.includes('edit-no-favorite')) {
            const archives: Array<any> = this.shipments.value;
            this.paginator.then((p) => p['totalDocs'] = p['totalDocs'] + data.data.length);

            data.data.forEach(load => {
                archives.unshift({ ...load });
            });

            this.shipments.next([
                ...archives,
            ]);
        }
    }
}