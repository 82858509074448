import { Injectable } from '@angular/core';
import { GlobalStore } from '../../store/store.service'
import { HttpResponse } from '@angular/common/http/http';
import { ToastService } from '../utils/toast.service'
@Injectable({
    providedIn: 'root'
})
export class ResponseLogs {
    constructor(private gs: GlobalStore, private toast: ToastService) {
        window.addEventListener('response', this.update.bind(this));
    }

    update({ detail }: { detail: HttpResponse<any> }): void {
        if(!detail.body.data) return this.toast.showToast('data is null', 'left', 'bottom')
        this.gs.update(detail.url, detail.body);
        this.showMessage(detail);
    }

    showMessage({ body: { data: { message } } }) {
        if(!message) return;

        this.toast.showToast(message, 'left', 'bottom');
    }
}