import { Component, OnInit, NgModule } from '@angular/core';
import { ShipmentService } from '@services';
import { ActivatedRoute } from '@angular/router'
@Component({
    selector: 'app-general-loads',
    templateUrl: './general-shipments.component.html'
})
export class GeneralLoadsComponent implements OnInit {
    token: string;
    shipments$
    tableColumns: string[] = ['Load ID', 'Origin City', 'Origin ST', 'Pickup', 'Destination City', 'Destination ST', 'Truck Type', 'F/P', 'Len', 'Wt'];
    headerPropertys = {
        'Origin City': 'originCity',
        'Origin ST': 'originState',
        'Pickup': 'pickup',
        'Destination City': 'destinationCity',
        'Destination ST': 'stateCity',
        'Truck Type': 'truckType',
        'F/P': 'fullOrPartial',
        'Len': 'length',
        'Wt': 'Weight',
        'Qty': 'loadInfo.quantity',
        'Load ID': 'importRef',
    }
    constructor(private shipmentService: ShipmentService, private route: ActivatedRoute) {
        this.token = this.route.snapshot.params.personalToken;
        this.getPersonalLoads();
    }
    ngOnInit(): void { }

    async getPersonalLoads() {
        try {
            let res = await this.shipmentService.getPersonalLoadboards(this.token)
            this.shipments$ = res;
        } catch (e) {
            console.error(e)
        }
    }
}

import { CommonModule } from '@angular/common';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
    declarations: [GeneralLoadsComponent],
    imports: [CommonModule, MatPaginatorModule, ScrollingModule],
    exports: [GeneralLoadsComponent],
})
export class GeneralLoadsModule { }