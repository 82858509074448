import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators'
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  createUser(user): Observable<any> {
    return this.http.post(`${environment.url}/user`, user);
  }

  createUserWithVerification(user): Observable<any> {
    return this.http.post(`${environment.url}/auth/create-account-user`, user);
  }

  deleteUser(userId): Observable<any> {
    return this.http.post(`${environment.url}/user/remove/${userId}`, { isActive: false });
  }

  editUser(user, id): Observable<any> {
    return this.http.patch(`${environment.url}/user/${id}`, user);
  }

  uploadPicture(picture): Observable<any> {
    let formData = new FormData();

    formData.append('file', picture, picture.name);

    return this.http.post(`${environment.url}/user/upload/picture`, formData);
  }

  setupList(setup): Observable<any> {
    return this.http.post(`${environment.url}/user/column-list`, setup)
  }

  getSetupList(): Observable<any> {
    return this.http.get(`${environment.url}/user/column/list`);
  }

  getUserList(query: { limit?: number, page?: number, dato?: string, companyId: string }): Observable<any> {
    let params = new HttpParams().set('limit', query.limit || 10).set('page', query.page || 1)
      .set('dato', query.dato || '').set('companyId', query.companyId);
    return this.http.get(`${environment.url}/lbn/user/list-user/sa`, { params }).pipe(
      map((response: any) => ({
        ...response, data: {
          ...response.data,
          docs: response.data.docs.filter(u => !u.isDeleted)
        }
      }))
    );
  }

  getUserListByLoadboardAndCompany(companyId, loadBoardId) {
    return this.http.get<any>(`${environment.url}/load-board/find-active-users-by-company/${loadBoardId}/${companyId}/sa`).pipe(
      map(response => ({
        ...response,
        data: {
          docs: [...response.data],
        }
      }))
    )
  }

  toggleActiveUser(userId, active) {
    return this.http.put(`${environment.url}/lbn/user/active-user/${userId}/sa`, {
      active
    })
  }

  sendVerificationEmail(userId) {
    return this.http.put(`${environment.url}/auth/send-verify-user/${userId}/sa`, {});
  }

  loginAsUser(userId) {
    return this.http.post(`${environment.url}/auth/login/sa`, {
      userId
    })
  }

  deleteUserFromSA(userId) {
    return this.http.delete(`${environment.url}/user/${userId}/sa`);
  }

  createUserSA(user): Observable<any> {
    return this.http.post(`${environment.url}/auth/create-account-user/sa`, {
      ...user
    })
  }

  editUserSA({ _id: userId, ...restUser }) {
    return this.http.put(`${environment.url}/lbn/user/${userId}/sa`, {
      ...restUser
    })
  }

  changeUserPassword(userId, password) {
    return this.http.put(`${environment.url}/user/change-password/${userId}/sa`, {
      password
    })
  }

  sendChangePasswordEmail(userId) {
    return this.http.put(`${environment.url}/auth/forgot-password/${userId}/sa`, {})
  }

  setBookable(userId:string, isBookable: boolean) {
    return this.http.put(`${environment.url}/user/set-isbookable/${userId}/sa`, { isBookable })
  }
}