import { Injectable } from '@angular/core';
import Swal from 'sweetalert2'
import { HttpErrorResponse, HttpResponse } from '@angular/common/http/http';
import { ReplaySubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ErrorLogs {
    private historialError: ReplaySubject<any> = new ReplaySubject();
    constructor() {
        window.addEventListener('error', this.showError.bind(this));
        window.addEventListener('responseError', this.showErrorResponse.bind(this));
    }

    showError({ detail }: { detail: HttpErrorResponse }) {
        let messageError = this.searchError(detail);
        Swal.fire('Error!', typeof messageError == 'object' ? messageError.map(error => `${error} <br>`).join(" ") : messageError, 'error');
        this.historialError.next(detail.error)
    }

    showErrorResponse({ detail }: { detail: HttpResponse<any> }) {
        Swal.fire('Error!', detail.body.message, 'error')
        this.historialError.next(detail.body)
    }

    searchError({ error }) {
        if (typeof error.message !== 'string') {
            return error.message.message.map((errorString: string) => `${errorString}`)
        } else {
            return error.message
        }
    }

    showHistorial() {
        return this.historialError;
    }
}