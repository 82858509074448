import { inject, Injectable } from "@angular/core";
import { BookingService } from "@services";
import { BehaviorSubject, Observable } from "rxjs";
import { tap, map } from "rxjs/operators";
import { SortBy } from "./sort.util";
import { IStore } from './store.interfaces'
import { io } from "socket.io-client";
import { environment } from '../environments/environment'

@Injectable({
    providedIn: 'any'
})
export class BookingStore implements IStore {
    private _booking: BookingService = inject(BookingService);
    private cacheBookings;
    private sort: SortBy = new SortBy();
    private itsSortBy: Set<string> = new Set();
    private sortValues = {}
    private filters = {}
    paginator: Promise<{}>;
    typeSort: string;

    constructor() {
        this.getBookings();
        this.getEventFromNode();
    }

    private getEventFromNode() {
        let messages = {
            'NegotiationCreated': 'New bid received',
            'NegotiationCounteroffer': 'Counteroffer received',
            'NegotiationDeclined': 'Bid Decline',
            'NegotiationAccepted': 'Load accepted',
            'ShipmentAccepted': 'Load accepted',
            'ShipmentReadyForPickup': 'Load booked and ready for pick up',
            'ShipmentDelivered': 'Load delivered.'
        }

        let socket = io(`${environment.socketUrl}`);

        socket.on(`notification-booking${localStorage.getItem('_id')}`, (ev) => {
            let shipment = {
                ...ev.data,
                text: messages[ev.message],
                showBid: ['NegotiationCreated'].includes(ev.message)
            }

            this.update('notification', shipment)
        })
    }

    getBookings() {
        this.cacheBookings = this.convertIntoBehavior(this._booking.getListBookings({ limit: 25, page: 1 }))
    }

    convertIntoBehavior(data: Observable<any>) {
        let bs = new BehaviorSubject([]);

        let sub = data.pipe(
            tap(value => {
                this.paginator = new Promise((d) => d(value.data));
            }),
            map(value => value.data.docs)
        ).subscribe((value) => bs.next(value));

        return bs;
    }

    set filter(obj) {
        this.filters = { ...obj }
    }

    get sortValue() {
        return { sortField: Object.keys(this.sortValues).join(), sortValue: Object.values(this.sortValues).join() }
    }

    updatedPaginator({ pageIndex, pageSize }) {
        this._booking.getListBookingsByFilter({ ...this.sortValue, page: pageIndex + 1, limit: pageSize }, this.filters).toPromise();
    }

    sortBy(propertyPath: string, stay: boolean = false, shipment?: Array<any>) {
        // SORT BY BACKEND
        let sortValue = this.sort.newSortBy(propertyPath);

        if (!sortValue) {
            delete this.sortValues[propertyPath];

            this.typeSort = undefined;

            return this._booking.getListBookingsByFilter({ ...this.sortValue, limit: 500, page: 1 }, { ...this.filters }).toPromise();
        }

        this.sortValues[propertyPath] = sortValue

        this._booking.getListBookingsByFilter({ ...this.sortValue, limit: 500, page: 1 }, { ...this.filters }).toPromise();

        this.typeSort = sortValue === 1 ? 'asc' : 'desc';
    }

    reset(): void {
        this.getBookings();
    }

    update(url: string, data: any) {
        const actionEdit = url.split(/[\/=]/gm);

        if (actionEdit.includes('filter?limit')) {
            if (this.itsSortBy.size > 0) {
                this.itsSortBy.forEach((sort) => {
                    this.paginator = new Promise((d, r) => d(data));
                    return this.sortBy(sort, true, data.docs)
                })
            } else {
                this.cacheBookings.next([...data.docs]);
                this.paginator = new Promise((d, r) => d(data));
            }
        }

        if (actionEdit.includes('notification')) {
            const bookings: Array<any> = this.cacheBookings.value;

            let index = bookings.findIndex((ship) => ship._id === data._id);

            bookings[index] = { ...data };


            this.cacheBookings.next([
                ...bookings
            ])
        }
    }

    get getLoads() {
        return this.cacheBookings
    }
}