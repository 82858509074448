import { inject, Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { map, mergeMap, shareReplay, tap } from 'rxjs/operators';
import { BehaviorSubject, forkJoin } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NotificationService implements OnInit {
    private _http: HttpClient = inject(HttpClient)
    private baseuRL = environment.url;
    public loadingLength = new BehaviorSubject(0);
    constructor() { }

    ngOnInit(): void { }

    getNotificationList(collectionName: 'shipment' | 'booking', isRead: boolean) {
        let params = new HttpParams().set('collectionName', collectionName).set('isRead', isRead ? 'Yes' : 'No');


        return this._http.get(`${this.baseuRL}/notifications?${params.toString()}`).pipe(
            map((result: any) => result.data)
        )
    }

    getRequireActions(collectionName: 'shipment' | 'booking') {
        return this._http.get(`${this.baseuRL}/${collectionName}/get-required-action/shipment/olp`).pipe(
            map((result: any) => result.data)
        )
    }

    getLengthOfNotificationAndActions() {
        return this.loadingLength.pipe(
            shareReplay(1),
            mergeMap(() => forkJoin({
                shipment: this.getNotificationList('shipment', false),
                booking: this.getNotificationList('booking', false),
                actionsShipment: this.getRequireActions('shipment'),
                actionsBooking: this.getRequireActions('booking'),
            })),
            map((obj: any) => {
                return {
                    notifications: [...obj.shipment, ...obj.booking].length,
                    actions:  [...obj.actionsShipment, ...obj.actionsBooking].length
                }
            }),
        )
    }

    markAsReadFromShipment(id: string) {
        return this._http.put(`${this.baseuRL}/shipment/read-by-shipment/${id}`, {}).pipe(
            map((result: any) => result.data),
            tap(() => this.loadingLength.next(0))
        )
    }
    markAsReaded(id: string) {
        return this._http.put(`${this.baseuRL}/notifications/${id}/read`, {}).pipe(
            map((result: any) => result.data),
            tap(() => this.loadingLength.next(0))
        )
    }
}