import { Injectable } from '@angular/core'
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { UtilsService } from '@services';

@Injectable({
    providedIn: 'root'
})
export class LBNService {
    constructor(private http: HttpClient, private utils: UtilsService) { }

    updateCompany(body, id): Observable<any> {
        return this.http.put(`${environment.url}/lbn/company/${id}`, body)
    }

    updateUser(body, id): Observable<any> {
        return this.http.put(`${environment.url}/lbn/user/${id}`, body)
    }

    getCountries() {
        return this.http.get(`${environment.url}/lbn/countries`)
    }

    getStates(country?: string): Observable<any> {
        return this.http.get<any>(`${environment.url}/lbn/states`, country ? {
            params: {
                country
            }
        } : undefined).pipe(
            map(data => ({
                ...data, data: data.data.map(
                    state => ({ ...state, state: this.utils.removeAccent(state.state) })
                )
            }))
        )
    }
}