import { Pipe, PipeTransform} from '@angular/core'

@Pipe({
    name: 'Loading',
    pure: true
})
export class MessagesLoading implements PipeTransform{
    private routesMessages: Record<string, string> = {
        'favorite': 'Loading Favorite List',
        'filter': 'Loading Active List',
        'archived': 'Loading Archived List',
        'websites': 'Loading Load Boards List',
        'list': 'Loading Headers',
        'column-list': 'Saving Header Width'
    }
    transform(value: any, ...args: any[]) {
        const route = value.split(/[\/=]/gm);

        return this.routesMessages[route[route.length - 1]];
    }
}