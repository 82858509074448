<footer class="flex justify-end items-center gap-2 w-full mt-4 pb-8">
    <button [disabled]="disabled" class="btn-dark-outline disabled:opacity-50 disabled:border-opacity-50"
        (click)="onCancel.emit()">
        <span class="underline decoration-2" matTooltip="ALT + X">X</span> Cancel
    </button>
    <!-- EDIT_FAVORITE -->
    <ng-container *ngIf="action === userAction.EditFavorite">
        <button [disabled]="disabled" class="btn-gray disabled:opacity-50" (click)="onSave.emit()">
            <span class="underline decoration-2" matTooltip="ALT + S">S</span>ave
        </button>
    </ng-container>
    <!-- EDIT_FAVORITE -->
    <!-- COPY_TO_ACTIVE -->
    <ng-container *ngIf="action === userAction.CopyToActive">
        <button [disabled]="disabled" class="btn-primary disabled:opacity-50" (click)="onCopyToActive.emit(false)">
            <i class="fas fa-save mr-1"></i> <span class="underline decoration-2" matTooltip="ALT + S">S</span>ave to Active
        </button>
        <button [disabled]="disabled" class="btn-primary disabled:opacity-50" (click)="onCopyToActive.emit(true)">
            <i class="fas fa-check mr-1"></i> Save to Active and <span class="underline decoration-2" matTooltip="ALT + P">P</span>ost
        </button>
    </ng-container>
    <!-- COPY_TO_ACTIVE -->
    <!-- VIEW_ARCHIVED -->
    <ng-container *ngIf="action === userAction.ViewArchived">
        <button [disabled]="disabled" class="btn-gray disabled:opacity-50" (click)="onCopyToFavorite.emit()">
            <span class="underline decoration-2" matTooltip="ALT + S">S</span>ave to Favorites
        </button>
        <button [disabled]="disabled" class="btn-primary disabled:opacity-50" (click)="onCopyToActive.emit(false)">
            <i class="fas fa-save mr-1"></i> <span class="underline decoration-2" matTooltip="ALT + S">S</span>ave to Active
        </button>
        <button [disabled]="disabled" class="btn-primary disabled:opacity-50" (click)="onCopyToActive.emit(true)">
            <i class="fas fa-check mr-1"></i> Save to Active and <span class="underline decoration-2" matTooltip="ALT + P">P</span>ost
        </button>
    </ng-container>
    <!-- VIEW_ARCHIVED -->
    <!-- FAVORITE BUTTONS -->
    <ng-container *ngIf="action === userAction.ViewFavorite">
        <button [disabled]="disabled" class="btn-gray disabled:opacity-50" (click)="onSave.emit()">
            <span class="underline decoration-2" matTooltip="ALT + S">S</span>ave
        </button>
        <button [disabled]="disabled" class="btn-primary disabled:opacity-50" (click)="onCopyToActive.emit(false)">
            <i class="fas fa-save mr-1"></i> <span class="underline decoration-2" matTooltip="ALT + S">S</span>ave to Active
        </button>
        <button [disabled]="disabled" class="btn-primary disabled:opacity-50" (click)="onCopyToActive.emit(true)">
            <i class="fas fa-check mr-1"></i> Save to Active and <span class="underline decoration-2" matTooltip="ALT + P">P</span>ost
        </button>
    </ng-container>
    <!-- FAVORITE BUTTONS -->

    <!-- FAVORITE BUTTONS -->
    <ng-container *ngIf="action === userAction.DuplicateActiveLoad">
        <button class="btn-primary" (click)="onSaveDuplicate.emit(false)">
            <span class="underline decoration-2" matTooltip="ALT + S">S</span>ave
        </button>
        <button class="btn-primary" (click)="onSaveDuplicate.emit(true)">
            <i class="fas fa-check mr-1"></i> Save & <span class="underline decoration-2" matTooltip="ALT + P">P</span>ost
        </button>
    </ng-container>
    <!-- FAVORITE BUTTONS -->

    <!-- ACTIVE BUTTONS -->
    <ng-container *ngIf="!action">
        <button [disabled]="disabled" class="btn-gray disabled:opacity-50" (click)="onCopyToFavorite.emit()">
            Copy to <span class="underline decoration-2" matTooltip="ALT + F">F</span>avorites
        </button>
        <button [disabled]="disabled" class="btn-primary disabled:opacity-50" (click)="onSave.emit(false)">
            <i class="fas fa-save mr-1"></i> <span class="underline decoration-2" matTooltip="ALT + S">S</span>ave
        </button>
        <button [disabled]="disabled" class="btn-primary disabled:opacity-50" (click)="onSave.emit(true)">
            <i class="fas fa-check mr-1"></i> Save & <span class="underline decoration-2" matTooltip="ALT + P">P</span>ost
        </button>
    </ng-container>
    <!-- ACTIVE BUTTONS -->

</footer>