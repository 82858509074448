import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class TmsService {
    constructor(private _http: HttpClient) { }

    getListTMS() {
        return this._http.get(`${environment.url}/tms/sa`)
    }

    createTMS({ name, serviceKey }: { name: string, serviceKey: string }) {
        return this._http.post(`${environment.url}/tms/sa`, { name })
    }
}