<section class="bg-white rounded-lg">
  <header class="flex flex-col">
    <!-- <b class="text-sm">Generate Iframe Link</b> -->
    <!-- <small>Personal load boards</small> -->

    <!-- <div class="text-xs font-semibold mt-5 mb-1">Preview:</div>
    <div class="rounded-lg border p-3">
      <iframe
        [src]="url"
        scrolling="no" frameborder="1" height="400px" width="100%" allowfullscreen></iframe>
    </div>

    <div class="text-xs font-semibold mt-5 mb-1">Your iFrame Code:</div>
    <div class="rounded-lg border p-3 flex">
      <code class="overflow-auto">
          {{generateIframeLink}}
      </code>
    </div> -->
  </header>

  <!-- <button class="btn-gray mb-6 mt-3" (click)="copyIframeLink()">
    Copy my loadboards iframe link
    <i class="fas fa-copy ml-2"></i>
  </button> -->

  <div class="postman-section mt-6">
    <span class="font-bold text-xl block">Posted Loads Service (JSON)</span>
    <span class="block text-gray-400 font-bold">Provides access to your posted loads in a JSON format. Please do not
      access more often than every 5 minutes.</span>
    <button class="btn-gray mb-2 mt-6" (click)="copyPostmanCollection()">
      Download JSON Postman Collection
      <i class="fa-solid fa-download"></i>
    </button>
    <span class="block text-gray-400 font-bold">Send a copy of this Postman Collection to your programmer.</span>
  </div>
</section>