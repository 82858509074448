import Swal, { SweetAlertOptions } from 'sweetalert2';

export function ShipmentLengthValidate(opts: SweetAlertOptions, propertyName: string) {
    return function (target: Object, propertyKey: string, descriptor: PropertyDescriptor) {
        let options: SweetAlertOptions = {
            title: 'Error!',
            text: 'Please select one or more rows first',
            icon: 'error'
        }


        let oldDescriptor = descriptor.value;

        descriptor.value = function (...args: any) {
            let shipments = Array.from(this[propertyName] || []);

            if (!shipments.length) {
                Swal.fire(options)
            } else {
                const result = oldDescriptor.apply(this, args)
                return result;
            }

        }

        return descriptor;
    }
}

export function ShipmentPostedValidation(opts: SweetAlertOptions, propertyName: string, hasToBe: boolean) {
    return function (target: Object, propertyKey: string, descriptor: PropertyDescriptor) {
        let options: SweetAlertOptions = {
            title: 'Error!',
            icon: 'error'
        }

        let oldDescriptor = descriptor.value;

        descriptor.value = function (...args: any) {
            const shipments = Array.from(this[propertyName] || []);
            const allPosted = shipments.filter((ship) => ship['posted'] === (!hasToBe ? 'Yes' : 'No')).length > 0;
            
            options['title'] = `Please select at least one "${!hasToBe ? 'Posted' : 'Unposted'}" shipment and try again.`;

            if (!allPosted && !hasToBe || hasToBe && !allPosted) {
                Swal.fire(options);
            } else {
                const result = oldDescriptor.apply(this, args)

                return result
            }
        }

        return descriptor;
    }
}

export function LoadboardActivate(opts: SweetAlertOptions) {
    return function (target: Object, propertyKey: string, descriptor: PropertyDescriptor) {
        let options: SweetAlertOptions = {
            title: 'Load can not be Posted!',
            text: 'Must first activate load boards.',
            showDenyButton: true,
            denyButtonText: 'Load Boards',
            denyButtonColor: '#555555',
            showConfirmButton: true,
            confirmButtonText: 'Ok',
            reverseButtons: true
        }

        let oldDescriptor = descriptor.value;

        descriptor.value = function (...args: any) {
            if (this.globalService.store.loadboards.stats.activeStats > 0) {
                const result = oldDescriptor.apply(this, args)

                return result
            } else {
                Swal.fire(options).then(event => {
                    if (event.isDenied) {
                        window.location.href = '#/app/settings/load-boards';
                    }
                })
            }
        }

        return descriptor;
    }
}

export function OpenAddUserValidate() {
    return function (target: Object, propertyKey: string, descriptor: PropertyDescriptor) {
        const oldValue = descriptor.value;

        descriptor.value = function (...args) {
            let arg = arguments[0];
            if (arg === 'Add User') {
                const result = oldValue.apply(this, args)
            }
        }
    }
}

export function FilterValidation() {
    return function (target: Object, propertyKey: string, descriptor: PropertyDescriptor) {
        const oldMethod = descriptor.value;
        let options: SweetAlertOptions = {
            title: 'Error!',
            icon: 'error',
            text: "You need to activated the filter mode or select one load"
        }
        descriptor.value = function (...args) {
            if (this.filterActive && this['shipmentsSelected'].size > 0) {
                const result = oldMethod.apply(this, args);
                return result;
            } else {
                Swal.fire(options)
            }
        }
        return descriptor;
    }
}

export function RemoveUserValidate() {
    return function (target: Object, propertyKey: string, descriptor: PropertyDescriptor) {
        const oldMethod = descriptor.value;

        let options: SweetAlertOptions = {
            title: 'Remove User',
            icon: 'question',
            text: "Are you sure to remove this user?",
            showCancelButton: true,
            showConfirmButton: true
        }

        descriptor.value = function (...args) {
            Swal.fire(options).then((option) => {
                if (option.isConfirmed) {
                    const result = oldMethod.apply(this, args);
                    return result;
                }
            })
        }
        return descriptor;
    }
}

export function ConfirmSaveChanges() {
    return function (target: Object, propertyKey: string, descriptor: PropertyDescriptor) {
        let options: SweetAlertOptions = {
            title: 'Confirm',
            text: 'Are you sure you want to save the changes?',
            icon: 'question',
            showCancelButton: true,
            showConfirmButton: true
        }


        let oldDescriptor = descriptor.value;

        descriptor.value = function (...args: any) {
            Swal.fire(options).then((option) => {
                if (option.isConfirmed) {
                    const result = oldDescriptor.apply(this, args)
                    return result;
                }
            })
        }
        return descriptor;
    }
}

export function ValueLengthValidate(fields: string[], strict: boolean) {
    return function (target: Object, propertyKey: string, descriptor: PropertyDescriptor) {
        let options: SweetAlertOptions = {
            title: 'Error',
            text: 'You need fill some field',
            icon: 'error',
        }

        let oldDescriptor = descriptor.value;

        descriptor.value = function (...args: any) {
            let values = fields.map((name) => this[name].value);
            let canContinue = !strict ? values.some(value => value.length > 0) : values.filter((value) => !value.length);

            if (strict && (!canContinue[0] || !canContinue) || !strict && !canContinue) {
                options['text'] = `You need fill ${strict ? 'all' : 'some'} fields`;

                Swal.fire(options);
            }
            if (!strict && canContinue) {
                const result = oldDescriptor.apply(this, args)
                return result;
            }
        }
        return descriptor;
    }
}